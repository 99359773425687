<template>
<f7-block>
    <f7-searchbar
        disable-button-text="Cancel"
        v-model:value="searchTerm"
        placeholder="Search PO Number - minimum 2 chars"
        :clear-button="true"
        v-on:click:clear="clearResults()"
        v-on:keyup.enter="search()"
        ></f7-searchbar>
</f7-block>
<f7-block v-if="searching">
    <p>Searching, please wait</p>
</f7-block>
<f7-block v-else class="no-margin">
    {{ results.length }} Results found
    <div v-if="results" class="data-table">
        <table>
            <thead>
                <th v-for="(header, index) in results[0]">
                    {{ header.name }}
                </th>
            </thead>
            <tbody>
                <tr v-for="(item, index) in results">
                    <template v-for="(col,i) in item">
                        <td v-if="i == 0" @click="$emit('clicked', col.value)" style="text-decoration:underline; cursor:pointer">{{ col.value }}</td>
                        <td v-else>{{ col.value }}</td>
                    </template>
                </tr>
            </tbody>
        </table>
    </div>
</f7-block>
   
</template>
<style scoped>
.small {
    font-size: 0.8rem;
}
</style>
<script>

export default {
    props: ['controller'],
    emits: ['clicked'],
    data: function () {
        return {
            results: [],
            searchTerm: null
        }
    },
    methods: {
        search:function () {
            var vm = this;
            if(this.searchTerm.length < 2) {
                return false;
            }
            this.searching = true;
            this.results = [];
            var vm = this;
            if(this.searchTerm) {
                
                vm.$root.$refs['data-request'].sendPostRequest({
                    url: '/' + vm.controller + '/searcher',
                    data : vm.searchTerm
                })
                .then(data => {
                    if(data.status == 'OK') {
                        vm.results = data.list;
                        vm.searching = false;
                    } 
                }, function() {});	
            }
        },
        clearResults: function() {
            this.results = [];
        }
    },
    mounted: function () {
    },


}
</script>