<template>
    <f7-card style="margin-top:4rem;">
        <h2>Choose Product</h2>
        <f7-block class="no-bottom-margin no-top-margin">
            <div class="grid grid-cols-3 grid-gap">
                <div v-for="(item) in product_category">
                    <f7-button fill tonal :title="item" @click="selectCategory(item)"><b>{{ item }}</b></f7-button>
                </div>
            </div>
        </f7-block>
        <template  v-if="chosen_category">
            <f7-list>
                <f7-chip v-for="(type) in getTypes()"
                    :text="type.name" 
                    @click="chosen_product.type = type"    
                    style="cursor: pointer; margin:3px; font-size: 1.2rem;"
                    :media="type.name.charAt(0)"
                    :media-bg-color="chosen_product.type == type ? 'green' : 'yellow'"
                    >
                </f7-chip>
                <f7-button @click="extended = !extended">{{ extended == true ? 'Hide ' : 'Show ' }} More Items</f7-button>
            </f7-list>
            <template v-if="chosen_product.type">
                <div class="grid grid-cols-3 grid-gap">
                    <div><f7-button color="pink" tonal @click="update( 'Ralawise')" style="margin-top:1rem;">Add Ralawise</f7-button></div>
                    <div><f7-button color="pink" tonal @click="update('Prestige ')" style="margin-top:1rem;">Add Prestige</f7-button></div>
                    <div><f7-button color="pink" tonal @click="update( 'Other')" style="margin-top:1rem;">Add Other</f7-button></div>
                    <div><f7-button color="orange" tonal @click="update('Customer',)" style="margin-top:0;">Add Customer</f7-button></div>
                    <div><f7-button color="teal" tonal @click="update('Pencarrie')"  style="margin-top:0;">Add Pencarrie</f7-button></div>
                    <div><f7-button color="teal" tonal @click="search = true" style="margin-top:0;">Search Pencarrie</f7-button></div>
                </div>
            </template>          
            <template v-if="search && chosen_product.type">
                <productsearch :productType="chosen_product.type.name"
                    v-on:chosenProduct="setProduct($event)"
                />
            </template>  
        </template>
        
    </f7-card>
    <f7-button fill large @click="$emit('closepanel')" style="margin-top:1rem;">Close</f7-button>
</template>

<script>
import ProductSearch from './../components/product-search.vue';
export default {
    components: {
        productsearch: ProductSearch
    },
    props: {
    },
    emits: ['chosenProduct','closepanel'],
    data: function () {
        return {
            product_category: ['Top', 'Bottom', 'Misc'],
            product_types: [
                {name: 'Apron', category: 'Top'},{name: 'Bag', category: 'Misc'},{name: 'Blouse', category: 'Top'},{name: 'Cardigan', category: 'Top'},{name: 'Coverall', category: 'Top'},{name: 'Fleece', category: 'Top'},{name: 'Gilet', category: 'Top'},{name: 'Headwear', category: 'Top'},{name: 'Hood', category: 'Top'},{name: 'Jacket', category: 'Top'},{name: 'Jumper', category: 'Top'},{name: 'Leisure Rugby Shirt', category: 'Top'},{name: 'Polo', category: 'Top'},{name: 'Scarf', category: 'Top'},{name: 'Shirt', category: 'Top'},{name: 'Shorts', category: 'Bottom'},{name: 'Soft Shell', category: 'Top'},{name: 'Suit Jacket', category: 'Top'},{name: 'Sweatshirt', category: 'Top'},{name: 'T-Shirt', category: 'Top'},{name: 'Tabard', category: 'Top'},{name: 'Tie', category: 'Top'},{name: 'Towel', category: 'Misc'},{name: 'Trousers', category: 'Bottom'},{name: 'Tunic', category: 'Top'}
            ],
            extended_product_types: [
                {name: 'Accessory', category: 'Misc'},{name: 'All in One', category: 'Top'},{name: 'Blanket', category: 'Misc'},{name: 'Dress', category: 'Top'},{name: 'Face Mask', category: 'Top'},{name: 'Footwear', category: 'Bottom'},{name: 'Gloves', category: 'Top'},{name: 'Legging', category: 'Bottom'},{name: 'Pyjamas', category: 'Top'},{name: 'Robe', category: 'Top'},{name: 'Skirt', category: 'Bottom'},{name: 'Socks', category: 'Bottom'},{name: 'Soft Toy', category: 'Misc'},{name: 'Underwear', category: 'Top'},{name: 'Waistcoat', category: 'Top'}
            ],
            chosen_category: null,
            chosen_product: {
                sku: null,
                type: null,
                style: null,
                source: null,
            },
            search: false,
            extended: false
        }
    },
    methods: {
        update(source) {
            this.chosen_product.sku = source;
            this.chosen_product.source = source;
            this.chosen_product.style = null ;
            this.$emit('chosenProduct', this.chosen_product);
            this.$emit('closepanel');
        },
        getTypes() {
            var list = this.product_types.filter(f => f.category == this.chosen_category);
            if(this.extended) {
                list = list.concat(this.extended_product_types.filter(f => f.category == this.chosen_category))
            }
            return list;
        },
        setProduct(item) {
            //result of search
            this.chosen_product.sku = item.sku;
            this.chosen_product.style = item.style;
            this.chosen_product.source = 'Pencarrie';
            this.$emit('chosenProduct', this.chosen_product);
            this.$emit('closepanel');
        },
        selectCategory(cat) {
            this.search = false;
            this.chosen_category = cat;
            this.chosen_product.sku = null;
            this.chosen_product.type = null;
            this.chosen_product.source = null;
            if(this.chosen_category == 'Misc') {
                this.chosen_product.sku = 'Misc';
                this.chosen_product.type = { name: 'Misc', category: 'Misc' };
            }
        }
    },
    mounted: function() {
    }, 
}
</script>