<template>
    <f7-block style="margin-top:1rem;">
        <h2>Search Product Database - {{ productType }}</h2>
        <f7-list>
            <f7-list-input style="width:48%; display: inline-block;"  label="Product Code" v-model:value="searchParams.product_code" />
            <f7-list-input style="width:48%; display: inline-block; float:right;" label="Style Code"  v-model:value="searchParams.style_code" />
            <f7-list-input label="Description"  v-model:value="searchParams.description" />
        </f7-list>
        <f7-block class="no-top-margin no-bottom-margin">
            <template v-for="colour in colour_groups">
                <f7-chip v-if="!searchParams.colours.includes(colour)" :text="colour" :color="colour.toLowerCase()" @click="addColour(colour)" style="margin:3px;"></f7-chip>
                <f7-chip v-else deleteable :text="colour" :color="colour.toLowerCase()" @delete="removeColour(colour)" style="margin:3px;"></f7-chip>
            </template>
        </f7-block>
        <f7-button fill @click="search()" style="margin-top:1rem;">Search</f7-button>
        <f7-block v-if="results" class="no-top-margin no-bottom-margin table-holder">
        <h3>{{ result_count }} Matches</h3>
        <div class="data-table">
            <table>
                <thead>
                    <tr>
                        <th>Options</th>
                        <th colspan="4">Description</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-if="results" v-for="(k) in Object.keys(results)">
                        <tr @click="show_detail == k ? show_detail = null : show_detail = k">
                            <td >{{ results[k].length }}</td>
                            <td colspan="4">{{ k  }}</td>
                        </tr>
                        <template v-if="k == show_detail">
                            <tr v-for="d in results[k]">
                                <td></td>
                                <td>{{ d.sku  }}</td>
                                <td>{{ d.size }}</td>
                                <td>{{ d.colour_name }}</td>
                                <td><f7-button small fill @click="select(k, d)">Select</f7-button></td>
                            </tr>
                        </template>
                    </template>
                </tbody>
            </table>
        </div>
        </f7-block>
        
    </f7-block>
</template>

<script>
import AutoSearch from './../components/auto-search.vue';
export default {
    components: {
        autosearch: AutoSearch
    },
    props: {
        productType: {
            type: [String],
            required: true
        },
    },
    emits: ['chosenProduct'],
    data: function () {
        return {
            searchParams: {
                product_type: '',
                product_code: '',
                style_code: '',
                description: '',
                colours: []
            },
            results: [],
            result_count: 0,
            show_detail: null,
            colour_groups: ['Black','Green','Grey','Blue','Red','Orange','White','Purple','Pink','Yellow','Patterns','Brown','Natural'],
            chosen_product: null
        }
    },
    methods: {
        search() {
            this.results = [];
            this.result_count= 0;
            this.show_detail= null;
            this.chosen_product = null;
            var vm = this;
            vm.$root.$refs['data-request'].sendPostRequest({
			    url: '/product/search',
                data: vm.searchParams
            })
            .then(data => {
                if(data.status == 'OK') {
                    vm.results = data.results;
                    vm.result_count = data.count;
                } else {
                    f7.dialog.alert("garment not found!", "Error");
                } 
            })
        },
        addColour(colour) {
            this.searchParams.colours.push(colour);
        },
        removeColour(colour) {
            this.searchParams.colours = this.searchParams.colours.filter(f => f !== colour);
        },
        select(k, d) {
            this.chosen_product = d;
            this.$emit('chosenProduct', { 'sku': d.sku, 'style' : k + ' - ' + d.colour_name + ', size: ' + d.size });
        }
    },
    mounted: function() {
        this.searchParams.product_type = this.productType;
    }, 
}
</script>