<template>
<f7-block style="height:100vh; overflow-y:scroll; padding-bottom: 5rem;">
    <f7-block-title><h2>Note Viewer</h2></f7-block-title>

    <f7-list no-hairlines-md style="margin-top:0; margin-bottom:0;">
        <f7-list-input :label="'Add note: ' + note.length + ' of 500'" type="textarea" placeholder="note text" v-model:value="note" maxlength="500"></f7-list-input>
    </f7-list>
    

    <div class="grid grid-cols-2 grid-gap">
        <div><f7-button fill @click="save();">Save</f7-button></div>
        <div><f7-button fill @click="close();">Close</f7-button></div>
    </div>
   <hr />
    <div v-for="(line,index) in notes" :key="index" style = "background-color:white; margin-bottom: 1px; padding: 3px;">
        {{ line.created_display }}: <i>{{ line.user }}</i><br>
        <b>{{ line.log_text }}</b>
    </div>
    
</f7-block>
</template>
<style scoped>
.small {
    font-size: 0.8rem;
}
</style>
<script>

export default {
    props: ['orderid'],
    data: function () {
        return {
            notes: [],
            note: ''
        }
    },
    methods: {
        getNotes() {
            var vm = this;
            vm.$root.$refs['data-request'].sendGetRequest('/order/notes/' + this.orderid)
            .then(data => {
                if(data.status == 'OK') {
                    vm.notes = data.list;
                } 
            }, function() {});  
        },
        close() {
            this.$emit('closepanel');
        },
        save() {
            if(this.note == '') {
                return;
            }
            var vm = this;
            vm.$root.$refs['data-request'].sendPostRequest({
                url: '/note/new_note/' + this.orderid,
                data : {
                    note : this.note,
                }
            })
            .then(data => {
                if(data.status == 'OK') {
                    vm.getNotes();
                    vm.note = '';
                } 
            }, function() {}); 
        }
    },
    mounted: function () {
        this.getNotes();
    }

}
</script>