	<template>
    <f7-page name="schedule" >
        <f7-navbar>
            <f7-nav-left><f7-link panel-open="left"><f7-icon f7="bars" color="blue"></f7-icon></f7-link></f7-nav-left>
            <f7-nav-title style="text-align:center; width:100%;"><img src="/assets/images/logo.svg" style="height:5rem;" alt="Taylors Embroidery and Print Logo"></f7-nav-title>
        </f7-navbar>
		<f7-block  style="margin-top:4rem">
			<h1>{{ month }} Run Schedule </h1>
			<div class="grid grid-cols-4 grid-gap">
				<div><f7-button fill text="Schedule Job" @click="showScheduler(false)" icon-f7="plus" /></div>
				<div><f7-button  tonal text="Schedule Ad Hoc Job" @click="showScheduler(true)" icon-f7="plus" />	</div>
			</div>
			<div v-if="calendar" class="data-table card" style="margin-top:0.5rem;">
				<table>
					<thead>
						<tr>
							<th></th>
							<th v-for="(machine, i) in machines">{{ machine.name }}</th>
						</tr>
					</thead>
					<tbody>
						<template v-for="(day ,index) in calendar" :key ="index">
						<tr>
							<td style="white-space:nowrap;">{{ index }}</td>
							<td v-for="(machine, i) in machines">
								<template v-for="(item, x) in day[machine.name]"> 
									<div v-if="item.order_id" style="white-space:nowrap !important; background-color: #FFFFFF; border-radius: 5px; width: 100%;" >
										<h5>{{ item.job_name }} ({{ item.garment_count }})</h5>
										<f7-button :href="'/order/' + item.order_id" small style="text-align:left; display: inline-block;">View</f7-button>
										<f7-button small style="float:right;" @click.prevent="deleteSchedule(item.job_name, item.run_schedule_id)">
											<f7-icon f7="trash" size="14"></f7-icon>
										</f7-button>
									</div>
									<f7-chip style="width: 100%; font-weight: bold;" v-else color="orange" :text="item.job_name" :deleteable="true" @delete.prevent="deleteSchedule(item.job_name, item.run_schedule_id)" />
								</template>
							</td>
							</tr>
							<tr v-if="index.includes('Sunday')">
								<td></td>
								<td v-for="(machine, i) in machines">
									<f7-button tonal :href="'/schedule/print_run?date=' + day.date + '&machine=' + machine.id" target="_blank" class="link external icon-button print">Print {{ machine.id == '0' ? 'unassigned' : machine.name + '\'s' }} Schedule</f7-button>
								</td>
							</tr>
						</template>
					</tbody>
				</table>
			</div>
			<div class="grid grid-cols-4 grid-gap">
				<div><f7-button class="color-green button-large button-fill" @click="move(-1)">previous</f7-button></div>
				<div></div>
				<div></div>
				<div><f7-button class="color-green button-large button-fill" @click="move(1)">next</f7-button>	</div>
			</div>
		</f7-block>


		<minischeduler v-if="calendar && showMiniScheduler" :adhoc="scheduleAdHoc" v-on:newJob="updateSchedule($event)" v-on:popup:closed="hideMiniScheduler()" />
	</f7-page>
	
	
</template>
<style>
.dayofmonth {
	border-bottom: solid 1px white;
}
h3 {
	margin:0;
}
td {
	border-right:solid 1px #eee;
}

</style>
<script>
import AutoSearch from './../components/auto-search.vue';
import MiniScheduler from '../components/mini-scheduler.vue';
import {  f7 } from 'framework7-vue';
export default {
    components: {
		autosearch : AutoSearch,
		minischeduler: MiniScheduler
    },	
	data() {
		return {
			calendar: [],
			month: '',
			offset: 0,
			machines: [],
			machine_count: 0,
			showMiniScheduler: false,
			scheduleAdHoc: false,
		}
	},
	methods:  {
		getYear: function() {
            var vm = this;
			vm.calendar = [],
			vm.$root.$refs['data-request'].sendPostRequest({
				url: '/scheduler/month',
				data : { offset : vm.offset, length : 1 }
			})			
            .then(data => {
                if(data.status == 'OK') {
                    vm.calendar = data.list;
					vm.month = data.month;
                } 
            }, function() {});
		},
		move: function(amount) {
			this.showMiniScheduler = false;
			this.offset += amount;
			this.getYear();
		},
		getMachines: function () {
            var vm = this;
            vm.$root.$refs['data-request'].sendGetRequest('/machine/list/')
            .then(data => {
                if(data.status == 'OK') {
                    vm.machines = data.list;
					vm.machine_count = data.count;
                } 
            }, function(){});
        },
		deleteSchedule: function(job_name, schedule_id) {
			if(confirm('delete run schedule for ' + job_name + ' are you sure?')) {
				var vm = this;
				vm.$root.$refs['data-request'].sendGetRequest('/scheduler/delete/' + schedule_id)
				.then(data => {
					if(data.status == 'OK') {
						vm.getYear();
					} 
				}, function(){});
			}
		},
		updateSchedule: function(event) {
			var vm = this;
			Object.keys(this.calendar).forEach(f => {
				
				if(event.datelist.includes(vm.calendar[f].date)) {
					vm.calendar[f][event.machine.name].push(
						{
							job_name: event.job_name == null ? event.name_override : event.job_name,
							order_id:event.order_id == "NULL" ? null : event.order_id,
							run_schedule_id: event.id,
							garment_count: event.garments
						}
					)
				}
			})
		},
		showScheduler(adhoc = false) {
			this.scheduleAdHoc = adhoc;
			this.showMiniScheduler = true;
		},
		hideMiniScheduler() {
			this.showMiniScheduler = false;

		},
       
	},
	mounted: function() {
		console.log('mouting');
		this.getYear();	
		this.getMachines();

	},
	computed: {
		today: function() {
			var today = new Date();
			var dd = String(today.getDate()).padStart(2, '0');
			var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
			var yyyy = today.getFullYear();

			return yyyy + '-' + mm + '-' + dd;
		}

	}
}

</script>

