<template>
<div class="data-table">
    <table style="background-color: white;">
        <thead>
            <tr>
                <th>Order Id</th>
                <th>Created</th>
                <th>Customer</th>
                <th>Job Name</th>
                <th>No.<br>Garments</th>
                <th>Order<br>Total</th>
                <th>Status</th>
                <th>Run</th>
                <th>Despatch</th>
                <th v-if="hideButtons != 1" width="10%"></th>
            </tr>
        </thead>
        <tbody >
            <tr v-for="(line, index) in modelValue" v-bind:key="index">
                <td><a :href="'/order/' + modelValue[index].id">{{ modelValue[index].id }}</a></td>
                <td>{{ modelValue[index].created_display }}</td>
                <td>{{ modelValue[index].account.account_name }}</td>
                <td>{{ modelValue[index].job_name }}</td>
                <td>{{ modelValue[index].garment_total }} <span v-if="modelValue[index].garment_order_status == 'To Order'" style="float:right" title="Garments not ordered"><f7-icon f7="exclamationmark_circle"></f7-icon></span></td>
                <td>{{ modelValue[index].total }}</td>
                <td>{{ modelValue[index].status }}</td>

                <td v-if="hideButtons ==1 ||  (modelValue[index].run_schedule.id && showSchedule==0) ">{{ modelValue[index].run_schedule.date_display }}</td>
                <td v-else-if="modelValue[index].run_schedule.id && showSchedule" @click.prevent="$emit('scheduleRun', index);" class="myLink">{{ modelValue[index].run_schedule.date_display }}</td>
                <td v-else><f7-button small @click.prevent="$emit('scheduleRun', index);" title="Schedule Job" icon-f7="calendar"></f7-button></td> 

                <td v-if="hideButtons == 1">{{ modelValue[index].dispatch_schedule.date_display }}</td>
                <td v-else-if="modelValue[index].dispatch_schedule.id" @click.prevent="$emit('scheduleDespatch', index);" class="myLink">{{ modelValue[index].dispatch_schedule.date_display }}</td>
                <td v-else><f7-button small  @click.prevent="$emit('scheduleDespatch', index);" title="Set Despatch Date" icon-f7="calendar"></f7-button></td>

                <td v-if="hideButtons != 1" style="text-align:right;white-space:nowrap;">
                    <div v-if="modelValue[index].status == 'Despatched'">
                        <f7-button small  @click.prevent="$emit('markInvoiced', index);" title="Mark as Invoiced" icon-f7="money_pound_circle"></f7-button> 
                    </div>
                    <div class="grid grid-cols-3 grid-gap" v-else>
                        
                        <f7-button small  :href="'/order/print/' + modelValue[index].id" class="link external icon-button print" target="_blank" title="Print Job Sheet" icon-f7="printer"></f7-button>
                        <f7-button small v-if="modelValue[index].status == 'Quote'" @click="$emit('markApproved', index);" title="Approve Order" icon-f7="checkmark_rectangle"></f7-button>
                        <f7-button small v-if="modelValue[index].status == 'Ready'" @click="$emit('markRun', index);" title="Mark Order as Run" icon-f7="checkmark_2"></f7-button>
                        <f7-button small v-if="modelValue[index].status == 'Run'" @click="$emit('markDespatched', index);" title="Mark Order as Despatched" icon-f7="shippingbox"></f7-button>
                        <f7-button small v-if="modelValue[index].status == 'Run'" @click="$emit('printDeliveryNote', index);" title="Print Delivery Note" icon-f7="doc_text"></f7-button>

                    </div>

                </td>
            </tr>
        </tbody>
    </table>

</div>        
</template>
<script>

export default {
    props: ['modelValue', 'showSchedule', 'hideButtons'],
    emits: ['markInvoiced', 'markApproved', 'scheduleRun', 'markRun','markDespatched', 'scheduleDepatch', 'printDeliveryNote'],
    data: function () {
        return {
            schedulerOpened: false,
            orderId : null
        }
    },
    methods: {
        schedule(index) {
            this.orderId = this.modelValue[index].id;
            this.schedulerOpened = true;
        },

    },
    mounted: function () {
    },
}
</script>
