<template>
<f7-card :title="(artworkId == null ? 'Add ' : 'View ') + account_name  + ' Artwork'">
    <f7-list>
        <f7-list-input required label="Name" v-model:value="artwork.name" placeholder="leave blank to use filename"></f7-list-input>
        <f7-list-item v-if="artwork.preview">
            <img :src="artwork.preview">
        </f7-list-item>

        <f7-list-item v-if="artwork.artwork_date">Date: {{ artwork.artwork_date }}</f7-list-item>

        <template v-if="!artwork.used_on_garments">
            <fileupload v-on:save-state="artworkUploaded($event)"></fileupload>
            <f7-list-item v-if="artwork.file"> 
                <div v-if="artwork.file.contentType.includes('image')" style="text-align:center;"><img :src="artwork.file.content" style="max-width: 200px; max-height: 200px;" /></div>
                <p v-if="artwork.file.filename" style="padding:2rem;"><f7-icon f7="checkmark"></f7-icon>File Uploaded</p>
            </f7-list-item>
        </template>
        <f7-list-item style="display:inline-flex; padding-right: 1rem;" :disabled="artwork.used_on_garments" radio 
            title="Embroidery" 
            :checked="artwork.artwork_type == 'Embroidery' ? true : false"
            @change="updateType('Embroidery')"
            >
        </f7-list-item>
        <f7-list-item style="display:inline-flex; padding-right: 1rem;" :disabled="artwork.used_on_garments" radio 
            title="Screen Print" 
            :checked="artwork.artwork_type == 'Screen Print' ? true : false"
            @change="updateType('Screen Print')"
            >
        </f7-list-item>
        <f7-block>
            <div class="grid grid-cols-2 grid-gap">
                <div v-if="artwork.id">
                    <f7-button class="button-small button-fill"  @click="download()">Download</f7-button>
                </div>
                <div v-if="!artwork.used_on_garments">
                    <f7-button class="button-small button-fill"  @click="save()">Save</f7-button>
                </div>
            </div>
        </f7-block>     
    </f7-list>

</f7-card>

</template>

<script>
import FileUpload from './file-upload.vue';
import {  f7 } from 'framework7-vue';
export default {
    components: {
        fileupload : FileUpload
    },
    emits: ['artwork'],
    props: {
        artworkId: {
            type: [Number, null],
            required: true
        },
        accountId: {
            type: [Number, null],
            required: false
        }
    },
    data: function () {
        return {
           artwork: {
            id: null,
            account_id: null,
            name: null,
            file: null,
            preview: null,
            artwork_type: 'Embroidery',
            artwork_date: null,
            used_on_garments: false
           },
           account_name: null
        }
    },
    methods: {
        getAccount() {
            var vm = this;
            vm.$root.$refs['data-request'].sendGetRequest({
			    url: '/account/name/' + this.artwork.account_id,
            })
            .then(data => {
                if(data.status == 'OK') {
                    vm.account_name = data.name;
                } 
            }, function() {});
        },
        loadArtwork() {
            var vm = this;
            vm.$root.$refs['data-request'].sendGetRequest({
			url: '/artwork/get/' + this.artworkId,
            })
            .then(data => {
                if(data.status == 'OK') {
                    vm.artwork.id = data.model.id;
                    vm.artwork.account_id = data.model.account_id;
                    vm.artwork.name = data.model.name;
                    vm.artwork.filename = data.model.filename;
                    vm.artwork.preview = data.model.preview + '?d=' + new Date();
                    vm.artwork.artwork_date  = data.model.original_artwork_date_formatted;
                    vm.artwork.artwork_type = data.model.artwork_type;
                    vm.artwork.used_on_garments = data.model.related_garment_refs.length == 0 ? false: true;
                    vm.getAccount();
                } 
            })
            
        },
        artworkUploaded(event) {
            this.artwork.file = event;
            if(!this.artwork.name) {
                this.artwork.name = event.filename.substr(0, event.filename.lastIndexOf('.'));
            }
        },
        download: function() {
            window.open('/artwork/download_artwork/' + this.artwork.id);
        },
        save: function() {
            var errors = [];
            if((!this.artwork.file && !this.artwork.id) ) {
                errors.push('File');
            }
            if(!this.artwork.name) {
                errors.push('Artwork name');
            }

            if(errors.length) {
                f7.dialog.alert(errors.join("<br>"), "Missing data");
                return;
            }

            var vm = this;
            vm.artwork.preview = null;
            vm.$root.$refs['data-request'].sendPostRequest({
            url: '/artwork/save/',
            data : {
                artwork_id: this.artwork.id,
                account_id: this.artwork.account_id, 
                file : this.artwork.file == null ? null : this.artwork.file.content,
                filetype: this.artwork.file == null ? null : this.artwork.file.contentType,
                name : this.artwork.name, 
                artwork_type : this.artwork.artwork_type 
            }
            }).then(data => {
                if(data.status == 'OK') {
                    vm.artwork.id = data.artwork.id;
                    vm.artwork.account_id = data.account_id;
                    vm.artwork.file = null;
                    vm.artwork.preview = data.artwork.preview + '?d=' + new Date();
                    vm.artwork.artwork_date  = data.artwork.original_artwork_date_formatted;
                    f7.dialog.alert(data.artwork.name + ' saved', 'Success'); 
                    vm.$emit('artwork', vm.artwork);
                } else {
                    f7.dialog.alert(data.message, 'Error'); 
                }
            }, function() {}); 
        },
        updateType: function (event) {
            this.artwork.artwork_type = event
        }
    },
    mounted: function() {
        console.log('mounting artwork');
        if(this.artworkId != null)
            this.loadArtwork();
        else  {
            this.artwork.account_id = this.accountId;
            this.getAccount();
        }
        
    }, 
}
</script>