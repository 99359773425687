<template>
	<f7-list no-hairlines-md>
		<f7-list-input label="Postcode" type="text" placeholder="enter postcode" v-model:value="postcode"></f7-list-input>
	</f7-list>
	<div class="grid grid-cols-2 grid-gap">
        <div><f7-button small tonal  @click="search()">search</f7-button></div>
        <div><f7-button small tonal @click="this.$emit('updateaddress', '')">cancel</f7-button></div>
	</div>
	<f7-list v-if="addresslist.length" no-hairlines-md style="margin:0;">
		<f7-list-input label="Address" type="select" placeholder="select address" v-model:value="address" @input="showAddress($event)">
			<option v-for="(line, index) in addresslist" :key="index" :value="addresslist[index].value"><span v-html="addresslist[index].text"></span></option>
		</f7-list-input>
	</f7-list>
	<f7-list v-if="address" no-hairlines-md style="margin:0;">
		<f7-list-input v-for="(element, index) in addressArray" :label="'Line ' + (index + 1)" type="text" v-model:value="addressArray[index]" :key="index"></f7-list-input>
		<f7-button small tonal @click="this.setAddress()">Set Address</f7-button>
	</f7-list>
</template>

<script>
    export default {
		emits: ['updateaddress'],
		data: function() {
			return {
				error: '',
				address : null,
				postcode : '',
				addresslist: [],
				addressArray: [],
			};
		},
		methods: {
			
			search: function() {
				var vm = this;
				vm.addresslist = [];
				$.get('https://api.getAddress.io/find/' + this.postcode + '?api-key=X' + '&sort=true&expand=true')
					.done(function (response) {
						if (response.addresses.length == 0) {
							alert("No addresses found!");
							return;
						}
						$.each(response.addresses, function (i, element) {
							var cleaned = element.formatted_address.filter(function (el) {
								return el != "";
							});

							vm.addresslist.push({ 'value' :  element.formatted_address.join('|') + '|' + response.postcode, 'text' : cleaned.join(", ") + ", " + response.postcode});
						});
					})
					.fail(function (error) {
							alert('Error from getaddress.io: ' + error.responseJSON.Message);
							return;
					});
			},
			setAddress: function(event) {
				//console.log('setting address' + event.target.value);
				//his.address = this.addressArray.join('|');
				this.$emit('updateaddress', this.addressArray.join('|'));
			},
			showAddress: function(event) {
				this.addressArray = event.target.value.split('|');
			}
		}
    };

	//ehkjmUFvjES_hLOhl_gM6A28239
</script>