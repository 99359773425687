import HomePage from "../pages/home.vue";
import OrderPage from "../pages/order.vue";
import CataloguePage from "../pages/catalogue.vue";
import NotFoundPage from '../pages/404.vue';
import AccountPage from '../pages/account.vue';
import ViewerPage from '../pages/viewer.vue';
import SchedulePage from '../pages/schedule.vue';
import GarmentOrderPage from '../pages/garment-order.vue';
import DashboardPage from '../pages/dashboard.vue';


var routes = [
	{
		path: "/",
		name: 'Home',
		component: HomePage,
	},
	{
		path: "/dashboard/",
		name: 'Dashboard',
		component: DashboardPage,
	},
	{
		path: "/order",
		name: 'Order',
		component: OrderPage,
	},
	{
		path: "/order/:orderId",
		name: 'Order',
		component: OrderPage,
	},	
	{
		path: "/viewer/:code",
		name: 'Viewer',
		component:ViewerPage,		
	},	
	{
		path: "/catalogue/",
		name: 'Catalogue',
		component: CataloguePage,
	},
	{
		path: "/account/",
		name: 'Accounts',
		component: AccountPage,
	},	
	{
		path: "/schedule/",
		name: 'Schedule',
		component: SchedulePage,
	},	
	{
		path: "/garment-order/",
		name: 'GarmentOrder',
		component: GarmentOrderPage,
	},		
	{
		path: '(.*)',
		component: NotFoundPage,
	},	

];

export default routes;