<template>
    <f7-page name="garment-order">
    <f7-toolbar top tabbar>
        <f7-link tab-link="#tab-1"  tab-link-active>Order Garments</f7-link>
        <f7-link tab-link="#tab-2" >Search</f7-link>
    </f7-toolbar>
	<f7-navbar>
		<f7-nav-left><f7-link panel-open="left"><f7-icon f7="bars" color="blue"></f7-icon></f7-link></f7-nav-left>
            <f7-nav-title style="text-align:center; width:100%;"><img src="/assets/images/logo.svg" style="height:5rem;" alt="Taylors Embroidery and Print Logo"></f7-nav-title>
    </f7-navbar>
    <f7-tabs style="margin-top: 4rem;">
        <f7-tab id="tab-1" class="page-content"   tab-active>        
            <f7-block class="menu" style="margin-top:4rem;">
                <div v-if="list" class="grid grid-cols-4 grid-gap">
                    <div>
                        <f7-list inline-labels no-hairlines-md style="margin-top:0; margin-bottom:0;">
                            <f7-list-input label="Order Date" type="datepicker" readonly v-model:value="order_date"  :calendar-params="{ dateFormat: 'dd/mm/yy', closeOnSelect: true }">
                            </f7-list-input>
                        </f7-list>
                    </div>
                    <div></div>
                    <div></div>
                    <div>
                        <f7-button fill large text="Save" @click="save();" class="noprint"></f7-button>
                    </div>
                </div>          
            </f7-block>        
            <f7-block  v-if="list" class="no-margin">
                <div class="data-table">
                    <table>
                        <thead>
                            <tr>
                                <th>Job</th>
                                <th></th>
                                <th class="noprint"></th>                                
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in list">
                                <td width="5%">{{ index }}</td>
                                <td>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Source</th>
                                                <th>Type</th>
                                                <th style="white-space: nowrap;">Sku</th>
                                                <th>Description</th>
                                                <th>Quantity</th>
                                                <th>Order</th>                           
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <template v-for="(line, i) in item">
                                                <tr v-if="parseInt(i) >= 0">
                                                    <td width="10%">{{ line.source }}</td>
                                                    <td width="10%">{{ line.type }}</td>
                                                    <td width="10%"  style="white-space: nowrap;">{{ line.sku }}</td>
                                                    <td width="40%">{{ line.description }}</td>
                                                    <td width="10%">{{ line.qty }}</td>
                                                    <td width="15%" class="noprint">
                                                        <f7-list>
                                                            <f7-list-item :value="false" checkbox @change="updatePlaced(index, i, $event)"></f7-list-item>
                                                            <f7-list-input v-if="line.order_placed" label="Order Ref" type="text" v-model:value="item[i].garment_order_ref"></f7-list-input>
                                                        </f7-list>
                                                        <p v-if="item.order_placed" style="margin-top:0">Order date: {{ formatDate(item.garment_order_date) }}</p>
                                                    </td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                </td>

                            </tr>
                        </tbody>
                    </table>
                </div>
            </f7-block>
        </f7-tab>
        <f7-tab id="tab-2" class="page-content">
            <f7-block  style="margin-top:4rem;">
                <f7-searchbar
                    disable-button-text="Cancel"
                    v-model:value="searchTerm"
                    placeholder="Search (taylors order ref or garment order ref)"
                    :clear-button="true"
                    v-on:click:clear="clearResults()"
                    v-on:keyup.enter="search()"
                    ></f7-searchbar>
            </f7-block>
            <f7-block v-if="searching">
                <p>Searching, please wait</p>
            </f7-block>
            <f7-block v-else class="no-margin">
                {{ Object.keys(results).length }} Results found
                <div v-if="results" class="data-table">
                    <table>
                        <thead>
                            <tr>
                                <th>Job</th>
                                <th class="noprint"></th>                                
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in results">
                                <td width="5%">{{ index }}</td>
                                <td>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Source</th>
                                                <th style="white-space: nowrap;">Sku</th>
                                                <th>Description</th>
                                                <th>Quantity</th>
                                                <th>Order Ref</th>                           
                                                <th>Order Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <template v-for="(line, i) in item">
                                                <tr v-if="parseInt(i) >= 0">
                                                    <td width="10%">{{ line.source }}</td>
                                                    <td width="10%"  style="white-space: nowrap;">{{ line.sku }}</td>
                                                    <td width="40%">{{ line.description }}</td>
                                                    <td width="10%">{{ line.qty }}</td>
                                                    <td width="10%"><b>{{ line.garment_order_ref }}</b></td>
                                                    <td width="10%">{{ line.garment_order_date }}</td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                </td>

                            </tr>
                        </tbody>
                    </table>
                </div>

            </f7-block>

        </f7-tab>
    </f7-tabs>
    </f7-page>
</template>
<style>
 @media print {
    .view-main .page-content {
        background-color: #ffffff;
     }
    .noprint {
        display: none;
    }
    table, .page, .page-content {
        overflow: hidden;
    }
}

</style>
<script>

export default {
    components: {
    },
    props: {
    },
    data: function () {
        return {
            list: null,
            order_date: [],
            updates: [],
            searching: false,
            searchTerm: null,
            results: {}
        }
    },
    methods: {
        getList: function() {
            var vm = this;
            vm.$root.$refs['data-request'].sendGetRequest('/garment_order/list')
            .then(data => {
                if(data.status == 'OK') {
                    vm.list = data.list;
                } 
            }, function() {});	
        },
        formatDate: function(x) {
            var today = new Date(x);
			var dd = String(today.getDate()).padStart(2, '0');
			var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
			var yyyy = today.getFullYear();

			return dd  + '/' + mm + '/' + yyyy;
        },
        /*update: function(index, event) {
            if(event.target.checked == true) {
                this.list[index].garment_order_date = this.order_date;
                this.updates.push({ orderid: this.list[index].orderid, date: this.order_date[0]});
            } else {
                for (var i = this.updates.length - 1; i >= 0; i--) {
                    if (this.updates[i].orderid === this.list[index].orderid) {
                        this.updates.splice(i, 1);
                    }
                }
            }
        },*/
        updatePlaced: function(orderref,itemline,event) {
            if(event.target.checked == true) {
                this.list[orderref][itemline].order_placed = true;
                this.list[orderref][itemline].garment_order_date = this.order_date[0];
                this.updates.push(orderref);
            } else {
                this.list[orderref][itemline].order_placed = false;
                this.list[orderref][itemline].garment_order_ref = null;
                this.list[orderref][itemline].garment_order_date = null;
                this.updates = this.updates.filter(f => f !== orderref)
            }
        },
        save:function() {
            var vm = this;
            var orderlist = [];
            vm.updates.forEach(f =>
                Object.keys(vm.list[f]).forEach(g => {
                    if(vm.list[f][g].order_placed) {
                        orderlist.push({
                            order_id: vm.list[f].orderid,
                            detail_id: vm.list[f][g].detailid,
                            garment_order_date: vm.list[f][g].garment_order_date,
                            garment_order_ref: vm.list[f][g].garment_order_ref
                        })
                    }
                })
            )


            vm.$root.$refs['data-request'].sendPostRequest({
                url: '/garment_order/update/',
                data : orderlist
            })
            .then(data => {
                if(data.status == 'OK') {
                    vm.updates = [];
                    vm.list = null;
                    vm.getList();
                } 
            }, function() {});
        },
        search:function () {
            var vm = this;
            this.searching = true;
            this.results = [];
            var vm = this;
            if(this.searchTerm) {
                
                vm.$root.$refs['data-request'].sendPostRequest({
                    url: '/garment_order/search/',
                    data : vm.searchTerm
                })
                .then(data => {
                    if(data.status == 'OK') {
                        vm.results = data.list;
                        vm.searching = false;
                    } 
                }, function() {});	
            }
        },
        clearResults: function() {
            this.results = {};
        }
    },
    mounted : function() {
        this.order_date = [ this.today ];
        this.getList();
    },
	computed: {
		today: function() {
			var today = new Date();
			var dd = String(today.getDate()).padStart(2, '0');
			var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
			var yyyy = today.getFullYear();

			return yyyy + '-' + mm + '-' + dd;
		},
        

	}
}
</script>

