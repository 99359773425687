<template>
    <f7-login-screen class="login-screen" :opened="loginRequired" @loginscreen:closed="loginRequired = false">
    <f7-page login-screen>
        <f7-login-screen-title>Taylors Order System</f7-login-screen-title>
        <f7-list form>
        <f7-list-input
            label="Email"
            type="email"
            placeholder="Your email"
            info=""
            v-model:value="email.value"
            validate
            required="true"
            :onValidate="(isValid) => email.valid = isValid"
        ></f7-list-input>
        <f7-list-input
            label="Password"
            type="password"
            placeholder="Your password"
            info=""
            v-model:value="password.value"
            required="true"
            validate
            :onValidate="(isValid) => password.valid = isValid"
        ></f7-list-input>
        </f7-list>
        <p v-if="message" style="text-align:center">{{ message }}</p>
        <f7-list>
        <f7-list-button @click="signIn">Sign In</f7-list-button>
        <f7-block-footer>Forgotten your password?</f7-block-footer>
        </f7-list>
    </f7-page>
    </f7-login-screen>
</template>

<script>
import { returnStatement } from '@babel/types';
import { f7 } from 'framework7-vue';
    export default {
    data() {
        return {
            loginRequired: false,
            email: {
                value: '',
                valid: false
            },
            password: {
                value: '',
                valid: false
            },
            message: '',
            valid: false
        };
    },
    methods: {
        sendPostRequest(parameters) {
            var self = this;
            return new Promise((resolve, reject) => {
                RendiliCore.Json.PostJsonData({
                    url: parameters.url,
                    data: parameters.data
                }).then(data => {
                    //success 200
                    if(data.status == 'OK') {
                        resolve(data);
                    } else {
                        //http request successed, unauthorised

                        reject();
                    }
                },
                function(xhr, statusText) {
                    console.log(xhr);
                    //show popup
                    self.showPopup('Error', 'Route does not exist');
                    reject();
                },
                function(xhr, statusText) {
                    //unauthorised 401
                    self.loginRequired = true;
                    document.addEventListener('ajax-login-complete', () => {
                        self.loginRequired = false;
                        document.removeEventListener('ajax-login-complete', null);
                        parameters.data.account_id = 1;
                        resolve(self.sendPostRequest(parameters));
                    });
                },
                function(xhr, statusText) {
                    //forbidden 403 - forbidden
                    //show popup
                    reject();
                });
            });
        },
        //TODO create getrequest function as above.
        sendGetRequest(parameters) {
            var self = this;
            return new Promise((resolve, reject) => {
                RendiliCore.Json.Get(parameters).then(data => {
                    //success 200
                    if(data.status == 'OK') {
                        resolve(data);
                    } else {
                        //http request successed, unauthorised
                        //show popup;
                        reject();
                    }
                },
                function(xhr, statusText) {
                    //failure 
                    //show popup

                    //self.showPopup('Error ' + xhr.status, 'Route does not exist: ' + parameters);
                    
                    reject({'ErrorCode' : xhr.status, 'Message' : 'Route does not exist: ' + parameters});
                },
                function(xhr, statusText) {
                    //unauthorised 401
                    self.loginRequired = true;
                    document.addEventListener('ajax-login-complete', () => {
                        self.loginRequired = false;
                        document.removeEventListener('ajax-login-complete', null);
                        resolve(self.sendGetRequest(parameters));
                    });
                },
                function(xhr, statusText) {
                    //forbidden 403 - forbidden
                    //show popup
                    reject();
                });
            });
        },        
        signIn() {
            //add login code, dispatchevent if successful
            if(!this.email.valid || !this.password.valid) {
                this.message = 'Invalid login information.';
                return;
            }
            var vm = this;
            RendiliCore.Json.PostJsonData({
                url: '/user/signin',
                data: {
                    email: vm.email.value,
                    password: vm.password.value,
                }
            })                   
            .then(function (data) {
                if(data.status == "Error") {
                    vm.message =  'Login failed: ' + data.message;
                } else {
                    document.dispatchEvent(new Event('ajax-login-complete'));
                }
            }, function(xhr, statustext) {
                vm.message = 'Login attempt failed';
            });
        },
        showPopup(title, text) {
            const self = this;
            // Create popup
            if (!self.popup) {
                self.popup = f7.popup.create({
                    content: `
                    <div class="popup">
                        <div class="page">
                        <div class="navbar">
                            <div class="navbar-bg"></div>
                            <div class="navbar-inner">
                            <div class="title">` + title + `</div>
                            <div class="right"><a href="#" class="link popup-close" @click="removePopup()">Close</a></div>
                            </div>
                        </div>
                        <div class="page-content">
                            <div class="block">
                            <p>` + text + `</p>
                            </div>
                        </div>
                        </div>
                    </div>
                    `.trim(),
                });
            }
            // Open it
            self.popup.open();
        },
        removePopup() {
            const self = this;
            // Destroy popup
            if (self.popup) self.popup.destroy();
        },
    },
    }
</script>