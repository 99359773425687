<template>
    <f7-list no-hairlines-md style="margin: 0">
        <f7-list-input ref="search" :label="labelText + ': ' + status" type="text" :placeholder="'search ' + labelText + 's'" v-model:value="searchtext" @keydown.esc="cancelSearch()" @input="fetchResults()" v-on:focus="$event.target.select(); fetchResults();"></f7-list-input>
        
            <ul :style="styleObject">
                <li v-for="(item, index) in suggestions" :key="index">
                    <p style="cursor:pointer; padding-right:1rem; margin:5px 0;" v-on:click.prevent="select(item)">
                        <span style="font-size:0.8rem"><span v-html="suggestions[index].extra"></span></span> {{ suggestions[index].value }} <span v-html="suggestions[index].info"></span>
                    </p>
                    <hr style="border-top: none; border-bottom: 0.5px solid rgba(0, 0, 0, 0.12)" />
                </li>
            </ul>
    </f7-list>
</template>
<style scoped>
.item-title {
    font-weight: bold;
}
</style>
<script>

export default {    
    
        props: {'url': String, 'param': String,  'disableonselected': String, 'isdisabled': Boolean, 'minchars': Number, 'labelText': String, 'position' : String},
        emits: ['refine'],
        data: function () {
            return {
                searchcount: 0,
                suggestions: [],
                chosen: '',
                searchtext : '',
                lastquery: null,
                styleObject: {
                    position: 'absolute',
                    'max-height': '161px',
                    'overflow-y': 'scroll',
                    'overflow-x': 'hide',
                    'background-color': 'white',
                    display: 'none',
                    'padding-left': '15px',
                    'padding-right' : '0',
                    'z-index': '1000',
                    'top' : '67px',
                    'width': '98%'
                },
                timer: null,
                status: ''
            };
        },
        methods: {
            fetchResults() {
                if (this.searchtext.length < this.minchars - 1) {
                    this.suggestions = [];
                    return;
                }
                var vm = this;
                if (vm.timer == null) {
                    vm.timer = setTimeout(function () {
                        vm.searchQuery();
                    }, 250);
                }
            },
            searchQuery() {
                var vm = this;
                this.status = 'searching...';
                vm.searchcount++;
                //if(!this.searchtext) {
                    vm.$emit('refine', { 'code' : '', 'desc' : ''});
                //}
                
                fetch(this.url + '?query=' + this.searchtext + '&count=' + this.searchcount + '&params=' + this.param, { headers: { 'X-Requested-With': 'XMLHttpRequest' } })
                    .then(response => {
                        return response.json()
                    })
                    .then(data => {
                        if (data.searchcount == vm.searchcount) {
                            vm.suggestions = data.suggestions;
                            vm.styleObject.display = 'block';
                            vm.status = vm.suggestions.length + ' results';
                        }
                        vm.cleartimeout();
                    });
            },
            cleartimeout: function () {
                if (this.timer != null) {
                    clearTimeout();
                    this.timer = null;
                    this.searchQuery(this.description);
                }
            },
            select(item) {
                var vm = this;
                clearTimeout();
                    this.clear(function () {

                    //vm.$emit('update:code', item.data);
                    //vm.$emit('update:description', item.value);
                    vm.searchtext = item.value;
                    if(item.value) {
                        vm.$emit('refine', { 'code' :  item.data, 'desc' : item.value});
                        vm.status = '';
                    }
                    
                });
                
                /*this.chosen = item.data;
                this.detail = item.value;
                this.searchtext = item.value.replace(/(<([^>]+)>)/gi, "");*/

                /*this.getInfo();
                if (this.disableonselected) {
                    this.searchenabled = false;
                }*/
            },
            clear(_callback)  {
                clearTimeout();
                this.timer = null;
                this.suggestions = [];
                this.styleObject.display = 'none';
                this.searchcount = 0;
                _callback();
            },
            focusInput() {
                this.$nextTick(() => this.$refs.search.focus());
            },
            cancelSearch() {
                this.suggestions = [];
                this.searchtext = '';
                this.focusInput();
            }
        },
        mounted: function () {
             console.log('mounted');
             if(this.position == 'relative') {
                this.styleObject.position = this.position;
                this.styleObject.top = '-8px';
             }
            //this.focusInput();
        },
    }
</script>