<template>
    <f7-block style="margin-top:4rem;">
        <h2>Configure Garment</h2>
        <f7-template v-if="chosen_garment">
            <garment :garment-id="chosen_garment.id"
                    :account-id="accountId"
                    v-on:garment="chosen_garment = $event"
            />
            <f7-block>
                <div class="grid grid-cols-2 grid-gap">
                    <div><f7-button style="margin-top:1rem;" fill large v-if="chosen_garment" @click="chosen_garment = null">Back</f7-button></div>
                    <div><f7-button style="margin-top:1rem;" fill large v-if="chosen_garment" @click="update()">Select this Garment</f7-button></div>
                </div>
            </f7-block>
        </f7-template>
        <f7-template v-else-if="garment_id">
            <garment :garment-id="garment_id"
                    :account-id="accountId"
                    v-on:garment="chosen_garment = $event"
            />
            
        </f7-template>
        <f7-template v-else-if="new_garment">
            <garment :garmentType="garmentType"
                    :account-id="accountId"
                    v-on:garment="chosen_garment = $event; new_garment = false"
            />
            
        </f7-template>
        <f7-template v-else>
            <f7-list media-list>
                <f7-list-item v-for="(garment, index) in garment_list"
                    :title="garment.config_type + ' - ' + garment.name" 
                    :subtitle="'Created: ' + garment.created_display"
                    :text="garment.customer_garment_artwork_placement.length + ' artworks'"
                    @click="chosen_garment = garment"               
                    >
                    <template #media>
                        <img :src="garment.img_path" style="height:70px;" />
                    </template>
                </f7-list-item>
                    
            </f7-list>
            <f7-button fill @click="newGarment()" style="margin-top:1rem;">Add New Garment</f7-button>    
        </f7-template>
        <f7-button fill large @click="$emit('closepanel')" style="margin-top:1rem;">Close</f7-button>
    </f7-block>
</template>

<script>
import Garment from './garment.vue';
export default {
    components: {
        garment: Garment
    },
    props: {
        accountId: {
            type: [Number],
            required: true
        },
        garmentId: {
            type: [Number, null],
            required: false
        },
        garmentType: {
            type: [String],
            required: true
        },
        configType: {
            type: [String],
            required: false
        },
    },
    emits: ['chosenGarment','closepanel'],
    data: function () {
        return {
            garment_list: [],
            chosen_garment: null,
            garment_id: null,
            new_garment: false
        }
    },
    methods: {
        getCustomerGarmentList() {
            var vm = this;
            this.garment_list = [];
            vm.$root.$refs['data-request'].sendPostRequest({
			url: '/garment/list/',
			data : {
				'account_id' : vm.accountId,
                'garment_type': vm.garmentType
            }    
            })        
            .then(data => {
                if(data.status == 'OK') {
                    vm.garment_list = data.list;

                } 
            }, function() {});
        },
        update() {
            this.$emit('chosenGarment', this.chosen_garment);
            this.$emit('closepanel');
        },
        newGarment() {
            this.new_garment = true;
        }
    },
    mounted: function() {

        this.garment_id = this.garmentId;
        this.getCustomerGarmentList();
    }, 
}
</script>