<template>
<f7-block style="height:100vh; overflow-y:scroll; padding-bottom: 4rem;">
    <f7-block-title><h2 style="margin:0">Account List</h2></f7-block-title>
    <f7-row v-if="!addAccount">
        <f7-col><autosearch ref="account" labelText="Customer Account" url="/account/search" :minchars="1" param="" v-on:refine="setAccount($event)" v-model="accountName"></autosearch></f7-col>
    </f7-row>
    <f7-block v-if="account && account.address_list.length && addAddress == 0">

            <f7-list no-hairlines-md>
            <f7-list-input label="Select Address" type="select" v-model:value="chosenAddress">
                <option value="">-- Choose Address --</option>
                <option v-for="(line, index) in account.address_list" :key="index" :value="account.address_list[index].formatted_address"><span v-html="account.address_list[index].formatted_address"></span></option>
            </f7-list-input>
            </f7-list>

    </f7-block>   
    <f7-block v-if="account && account.id && !addAddress" class="no-top-margin">
        
        <f7-button  class=" button-small button-fill" @click="addAddress= 1">Add account Address</f7-button>
    </f7-block>  
    <template v-if="account != null">
        <f7-block v-if="addAccount">
            <h3 style="margin:0;">Create New Account</h3>
            <f7-list no-hairlines-md>
                <f7-list-input label="Company/Account name" type="text" placeholder="Company/Account name" v-model:value="account.account_name" :required="true"></f7-list-input>
                <f7-list-input label="Contact name" type="text" placeholder="Contact name" v-model:value="account.contact_name"  :required="true"></f7-list-input>
                <f7-list-input label="Telephone" type="text" placeholder="Telephone" v-model:value="account.telephone"  :required="true"></f7-list-input>
                <f7-list-input label="Email" type="text" placeholder="Email" v-model:value="account.email"  :required="true"></f7-list-input>
            </f7-list>
        </f7-block>    
        <f7-block v-if="addAccount || addAddress">
                <p>Address</p>
                <addresssearch v-on:updateaddress="setAddress($event)"></addresssearch>
        </f7-block>   
    </template>   
    <template v-if="errors.length">
        <p v-for="(err, index) in errors" v-bind:key="index">{{ errors[index] }}</p>
    </template>     
    <template v-if="!addAccount && !addAddress && !(account && account.id)">
        <div class="grid grid-cols-2 grid-gap">
            <div><f7-button class="button-fill" @click="addAccount = 1;newAccount()">Add new Account</f7-button></div>
            <div><f7-button class="button-fill" @click="closeSearch();">Close</f7-button></div>
        </div>
    </template>
    <template v-if="addAccount || addAddress">
        <div class="grid grid-cols-2 grid-gap">
        <div><f7-button class=" button-fill" @click="validate()">Save</f7-button></div>
        <div width="50"><f7-button class=" button-fill" @click="closeSearch();">Close</f7-button></div>
        </div>
    </template>

    <template v-if="account && account.id && chosenAddress && !addAddress">
        <div class="grid grid-cols-2 grid-gap">
            <div><f7-button class="button-fill" @click="useAccount()">Select Account</f7-button></div>
            <div><f7-button class="button-fill" @click="closeSearch();">Close</f7-button></div>
        </div>
    </template><!--
    <f7-row style="margin-top:2rem;" v-if="addAddress && !(accountRef && chosenAddress)">
        <f7-col><f7-button class="color-pink button-small button-fill" @click="validate()">Save</f7-button></f7-col>
        <f7-col width="50"><f7-button class="color-pink button-small button-fill" @click="closeSearch();">Close</f7-button></f7-col>
    </f7-row>-->

</f7-block>
</template>
<style scoped>
    .list {
        margin-top: 0;
        margin-bottom: 0;
    }
</style>
<script>
import AutoSearch from './../components/auto-search.vue';
import AddressSearch from './../components/address-search.vue';
export default {
    components: {
        autosearch : AutoSearch,
        addresssearch: AddressSearch
    },
    props: ['addaccount'],
    emits: ['selectaccount'],
    data: function () {
        return {
            account: null,
            chosenAddress: '',
            addAccount: 0,
            addAddress: 0,
            errors: [],
        }
    },
    methods: {
        useAccount() {
            this.$emit('selectaccount', {'account' : this.account, 'address' : this.chosenAddress});
        },
        validate() {
            this.errors = [];
            console.log('validating');
            if(this.addAccount == 1) {
                console.log('adding account');
                if(!this.account.account_name) {
                    this.errors.push('Account Name is required');
                }
                if(!this.chosenAddress) {
                    this.errors.push('Address is required');
                }
                if(this.errors.length == 0) {
                    //attempt to save
                    this.saveAccount();
                }
            } else if(this.addAddress) {
                if(!this.chosenAddress) {
                    this.errors.push('Address is required');
                }
                if(this.errors.length == 0) {
                    //attempt to save
                    this.saveAddress();
                }
            }
        },
        saveAccount() {
            console.log('saving account');
            var vm = this;
            vm.$root.$refs['data-request'].sendPostRequest({
			url: '/account/save/',
			data : {
				account : this.account, address : this.chosenAddress
			}
            }).then(data => {
                if(data.status == 'OK') {
                    vm.account = data.account;
                    vm.$emit('selectaccount', {'account' : vm.account, 'address' : data.address.formatted_address});
                } 
            }, function() {});  
        },
        saveAddress: function() {
            var vm = this;
            vm.$root.$refs['data-request'].sendPostRequest({
			url: '/account/saveaddress/',
			data : {
				'id' : this.account.id, 'address' : this.chosenAddress
			}
            })
            .then(data => {
                if(data.status == 'OK') {
                    //vm.chosenAddress = data.address_id;
                    vm.addAddress = 0;
                    vm.$emit('selectaccount', {'account' : vm.account, 'address' : data.address.formatted_address});
                    this.addAddress = 0;
                } 
            }, function() {});  
        },        
        setAccount: function (event) {
            var vm = this;
            vm.$root.$refs['data-request'].sendGetRequest('/account/detail/' + event.code)
            .then(data => {
                vm.account = data.account;
            }, function() {});
        },
        newAccount: function() {
            var vm = this;
            vm.$root.$refs['data-request'].sendGetRequest('/account/new/')
            .then(data => {
                vm.account = data.account;
                
            }, function() {});
        },
        setAddress(event)    {
            if(event != '') {
                this.chosenAddress = event;
            }
            if(this.addAccount == 0) {
                this.saveAddress();
            }
           
        },
        closeSearch() {
            this.$emit('closepanel');
        } 
    },
    mounted: function () {
        this.newAccount();
        this.addAccount = this.addaccount;
    }

}
</script>