<template>
    <f7-block style="margin-top:4rem;">
        <h2>File Store: <span v-if="storeid">{{ storeid }}</span> <span style="float:right">{{ files.length }}</span></h2>
		<p>Click to download</p>
		<template v-if="storeid">
			<f7-list style="background-color: #FFFFFF; margin:1rem 0;">
				<f7-list-item v-for="(f, index) in files"
					:title="f.name"
					:link="f.url"
					external="true"
					target="_blank"
					>
					<template #media>
						<f7-icon f7="doc"></f7-icon>
					</template>
				</f7-list-item>
			</f7-list>
			<h3>Upload File</h3>
			<div style="padding: 1rem;">
				<fileupload  v-on:save-state="uploadFile($event)" />
			</div>
		</template>   
		<f7-button v-else>Create Store</f7-button>
		<f7-button fill large @click="$emit('closepanel')" style="margin-top:1rem;">Close</f7-button>
	</f7-block>
</template>


<script>
import {  f7 } from 'framework7-vue';
import FileUpload from './file-upload.vue';
export default {
	components: {
		fileupload : FileUpload
	},
	props: {
        orderId: {
            type: [Number],
            required: false
        },
	},
	emits: ['closepanel'],
	data: function() {
		return {
			storeid: null,
			files: [],
		};
	},
	methods: {
        uploadFile: function(event) {
            var vm = this;
            vm.$root.$refs['data-request'].sendPostRequest({
            url: '/order/addFile/',
            data : {
                orderid: this.storeid, 
                file : event.content,
                filetype: event.contentType,
                filename : event.filename, 
            }
            }).then(data => {
                if(data.status == 'OK' ) {
                    vm.files.push(data.file);
                } else {
                    f7.dialog.alert("An error occured !", "Error");
                }
            })
        },
		getFiles: function(event) {
            var vm = this;
            vm.$root.$refs['data-request'].sendPostRequest({
            url: '/order/getFiles/',
            data : {
                orderid: this.storeid, 
            }
            }).then(data => {
                if(data.status == 'OK' ) {
                    vm.files = data.files;
                } else {
                    f7.dialog.alert("An error occured !", "Error");
                }
            })
        },

	},
	mounted: function() {
        if(this.orderId != null) {
            this.storeid = this.orderId;
			this.getFiles();
        } 
    }, 
};
</script>