<template>
<f7-page name="catalogue" id="catalogue">
	<f7-navbar>
		<f7-nav-left><f7-link panel-open="left"><f7-icon f7="bars" color="blue"></f7-icon></f7-link></f7-nav-left>
            <f7-nav-title style="text-align:center; width:100%;"><img src="/assets/images/logo.svg" style="height:5rem;" alt="Taylors Embroidery and Print Logo"></f7-nav-title>
	</f7-navbar>
	<f7-block  class="no-bottom-margin no-top-margin">
		
		<div class="block">
			<div class="grid grid-cols-3 grid-gap">
				<div>
					<f7-block-title>Categories</f7-block-title>
					<f7-list v-if="categories" class="category-scrolling-area">
						<f7-list-item v-for="(cat, index) in categories" :key="index" :title="categories[index].typename" link="#" :class="[categories[index].typename == currentCategory.typename ? 'selected' : '']" @click="loadProducts(index)" style="cursor:pointer;"></f7-list-item>
					</f7-list>
				</div>
				<div>
					<f7-searchbar search-container=".search-list" search-in=".item-title"></f7-searchbar>
					<f7-list class="searchbar-not-found">
						<f7-list-item title="Nothing found"></f7-list-item>
					</f7-list>
					<f7-list class="search-list searchbar-found scrolling-area">
						<f7-list-item v-for="(style, index) in currentCategory.styles" :key="index" :title="currentCategory.styles[index].style_code + ' ' + currentCategory.styles[index].description" style="cursor:pointer;" @click="getDetails($event, index)" :class="[currentProduct &&  currentCategory.styles[index].style_code == currentProduct.style_code ? 'selected' : '']"> 
						<template #media>
							<img :src="currentCategory.styles[index].image" width="80" />
						</template>
						</f7-list-item>
					</f7-list>
				</div>
				<div>
					<f7-block-title>Detail</f7-block-title>
					<f7-card class="product-detail" style="margin:0;" v-if="currentProduct">
						<f7-card-header :style="'background-image: url(' + currentProduct.image + ');'"></f7-card-header>
						<f7-card-content>
						<h2>{{ currentProduct.description }}</h2>
						<h4>Available Sizes</h4>
						<ul v-if="currentProduct.info">
							<li v-for="(size, index) in currentProduct.info.sizes" v-bind:key="index"><b>{{ currentProduct.info.sizes[index].size }}</b> - {{ currentProduct.info.sizes[index].detail }}</li>
						</ul>
						<p v-else>loading sizes...</p>
						<h4>Available Colours</h4>
						<ul v-if="currentProduct.info">
							<li v-for="(colour, index) in currentProduct.info.colours" v-bind:key="index"><b>{{ currentProduct.info.colours[index].colourway_code }}</b> - {{ currentProduct.info.colours[index].colour_name }}</li>
						</ul>			
						<p v-else>loading colours...</p>
						</f7-card-content>
					</f7-card>
				</div>
			</div>
		</div>
	</f7-block>
</f7-page>
</template>
<style scoped>


.product-detail .card-header {
	margin:0;
	height: 14rem;
	background-size: contain;
	background-position: center;
	color: #fff;
      background-repeat: no-repeat;
}
.product-detail ul {
	list-style-type: none;
	padding-left: 0;
	font-size: 0.8rem;
	margin:0;
}
</style>
<script>

export default {
	data() {
		return {
			categories: [],
			currentCategory: '',
			currentProduct: '',
		}
	},

	methods: {
		loadProducts(i) {
			this.currentCategory = this.categories[i];
			this.currentProduct = '';
			
			var vm = this;
			if(!vm.categories[i].styles.length) {
				vm.$root.$refs['data-request'].sendGetRequest('/catalogue/listing/' + this.currentCategory.typename)
				.then(data => {
					vm.categories[i].styles = data.list;
				}, function() {});
			}
		},
		getDetails(event, index) {
			var vm = this;
			this.currentProduct = this.currentCategory.styles[index];
            var catid = this.categories.findIndex(f => f.typename == this.currentCategory.typename);
			if(!vm.categories[catid].styles[index].info) {
				vm.$root.$refs['data-request'].sendGetRequest('/catalogue/detail/' + this.currentProduct.style_code)
				.then(data => {
					vm.categories[catid].styles[index].info = data.info;
					this.currentProduct.info =  data.info;
				}, function() {});
			}
		}
	},
	mounted: function() {
        var vm = this;
		vm.$root.$refs['data-request'].sendGetRequest('/catalogue/categories')
		.then(data => {
			console.log(data);
			vm.categories = data.list;
		}, function() {});
		console.log('mounted')
	}
};
</script>

