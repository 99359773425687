<template>
    <f7-page v-if="order" name="viewer">
        <f7-navbar>
            <f7-nav-title style="text-align:center; width:100%;"><img src="/assets/images/logo.svg" style="height:5rem;" alt="Taylors Embroidery and Print Logo"></f7-nav-title>
            <f7-nav-right>
                <f7-button fill v-if="order && order.status == 'Quote'"  @click="approve()">Approve</f7-button>
                <f7-button  @click="openContact= true">Contact Us</f7-button>
            </f7-nav-right>            
        </f7-navbar>
        <f7-block style="margin-top:6rem;"  v-if="order">
            <p v-if="order.status == 'Quote'" style="color:red;">This order needs to be confirmed, please check the details below:- </p>
            <div class="grid grid-cols-3 grid-gap">
                <div>
                    <b>{{ order.account.account_ref }} {{ order.account.account_name }}</b><br>
                    <span v-html="order.delivery_address"></span>
                </div>
                <div>
                    <b>Contact Name</b> {{ order.contact_name }}<br />
                    <b>Contact Telephone</b> {{ order.contact_telephone }}<br />
                    <b>Contact Email</b> {{ order.contact_email }}<br />
                    <b>Customer Ref / PO</b> {{ order.customer_reference }}<br />
                </div>           
                <div>
                    Created Date: {{ order.created_display }}<br>      
                    <div v-if="order.id">                    
                        <span>Order Reference: <b>{{ order.id }}</b></span><br>
                        <span>Order Status: <b>{{ order.status }}</b></span>
                    </div>   
                </div>
            </div>
           
            
            <f7-block >
                <f7-block-title class="no-top-margin"><h3>Order Items</h3></f7-block-title>
                <div  class="data-table card">
                    <table>
                        <thead>
                            <tr>
                                <th>Source</th>
                                <th width="">Type</th>
                                <th>Description</th>
                                <th>Sku</th>
                                <th class="numeric-cell">Quantity</th>
                                <th>Customisation</th>
                                <th class="numeric-cell">Line Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(line, index) in order.order_details" v-bind:key="index">
                                <td>{{ line.garment_source }}</td>
                                <td>{{ line.garment_type }}</td>
                                <td>{{ line.description }}</td>
                                <td>{{ line.sku }}</td>
                                <td class="numeric-cell">{{ line.quantity }}</td>
                                <td>{{ line.customer_garment.name }}</td>
                                <td class="numeric-cell">{{ line.line_total }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </f7-block>
            <f7-block>
                <div class="grid grid-cols-2 grid-gap">
                    <div></div>
                    <div>
                        <f7-list inline-labels no-hairlines-md v-if="order.account" style="margin:0">
                            <f7-list-input label="Carriage £" type="number" placeholder="carriage" v-model:value="order.carriage" :disabled="true"></f7-list-input>
                            <f7-list-input label="Setup Cost £" type="number" placeholder="setup cost" v-model:value="order.setup" :disabled="true"></f7-list-input>
                            <f7-list-input label="Sub Total £" type="number" readonly="true" v-model:value="order.subtotal" :disabled="true"></f7-list-input>
                            <f7-list-input label="VAT £" type="number" readonly="true"  v-model:value="order.vat" :disabled="true"></f7-list-input>
                            <f7-list-input label="Total £" type="number" readonly="true" v-model:value="order.total" :disabled="true"></f7-list-input>
                        </f7-list>
                    </div>
                </div>
            </f7-block>
        </f7-block>
        <f7-popup v-model:opened="openContact">
            <f7-page>
                <f7-navbar title="Contact Us">
                    <f7-nav-right>
                        <f7-link popup-close>Close</f7-link>
                    </f7-nav-right>
                </f7-navbar>
            <f7-block style="margin-top:5rem;">
                <p>If you have any questions or queries regarding this order, please contact us:</p>
                <p>Telephone: <f7-link external href="tel:+4401264324383"><b>01264 324383</b></f7-link></p>
                <p>Email: <f7-link external href="mailto:info@taylorsembroidery.co.uk"><b>info@taylorsembroidery.co.uk</b></f7-link></p>
                
                <p style="margin-top:2rem;">Please quote order reference  <b>{{ order.id }}</b></p>
            </f7-block>
        </f7-page>
        </f7-popup>
   </f7-page>



   
</template>

<script>

import OrderLine from './../components/order_line.vue';
import {  f7 } from 'framework7-vue';
export default {
    components: {
        orderline: OrderLine,
    },
    props: {
        code: String
    },
    data: function () {
        return {
            order: null,
            openContact: false
        }
    },
    methods: {

        approve: function() {
            var vm = this;
            fetch('/viewer/approve/' + this.order.id, { method: 'POST', headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' }, body: JSON.stringify()}) 
                .then(response => {
                return response.json()
                })
                .then(data => {
                    if(data.status == 'OK') {
                        f7.dialog.alert('Thank you. You will receive a confirmation shortly.', "Order Approved");
                        vm.order.status = data.order_status;
                    } else {
                        f7.dialog.alert('Hmm, something didn\'t quite work out, please contact us.', "Error");
                    }
                })
        }
    },
    mounted : function() {
        var vm = this;
        fetch('/viewer/resolve/' + this.code)
        .then(response => {
            return response.json()
        })
        .then(data => {
            vm.order = data.order;
        });
    }
}
</script>

