<template>
     <f7-card :class="name" class="schedule">
        <h3>{{ name }} Schedule</h3>
        <f7-block v-for="(item, index) in tasks">

                <span class="day" :class="index == 'Today' ? 'today' : ''">{{ index }}</span>
                <div v-if="item.length">
                    <p  v-for="(day, index) in item" style="border-bottom: solid 1px #eee;">
                    <span style="width:100%;" v-if="day.schedule.order_id != null">
                        <a :href="'/order/' + day.schedule.order_id">{{ day.schedule.order_id   }} - {{ day.schedule.job_name }}</a>
                        <template v-if="name == 'Run'">
                            <a v-if="day.status != 'Run'" class="icon-button mark" href="#" @click.prevent="mark(day.schedule.order_id);" :title="'Mark as ' + status " style="float:right;"><f7-icon f7="checkmark"></f7-icon></a>
                            <span style="float:right" v-else><f7-icon f7="checkmark_2"></f7-icon></span>
                        </template>
                        <template v-else>
                            <a v-if="day.schedule.status != 'Despatched'" class="icon-button mark" href="#" @click.prevent="mark(day.schedule.order_id);" :title="'Mark as ' + status " style="float:right;"><f7-icon f7="checkmark"></f7-icon></a>
                        <span style="float:right" v-else><f7-icon f7="checkmark_2"></f7-icon></span>
                        </template>
                    </span>
                    <span v-else>
                        {{ day.schedule.job_name }}
                    </span>
                    </p>
                </div>
                <div v-else>
                    <p>-</p>
                </div>

            </f7-block>

            <f7-block v-if="completed.length > 0" >
                <h3>Past {{ name }} Dates:</h3>
                <p  v-for="(item, index) in completed"><a :href="'/order/' + item.order_id" style="font-size:0.9rem;">{{ item.order_id }} - {{ item.job_name }} ({{ item.date_display }})</a> 
                <a class="icon-button mark" href="#" @click.prevent="mark(item.order_id);" :title="'Mark as ' + status " style="float:right;"><f7-icon f7="checkmark"></f7-icon></a>
                </p>
            </f7-block>
       
    </f7-card>
</template>
<style scoped>
p {
    margin: 0.3rem 0 0.3rem 0.5rem;
}
</style>
<script>

export default {
    props: ['startDate', 'length', 'action', 'name', 'status'],
    emits: ['statuschange'],
    data: function () {
        return {
            tasks: [],
            completed: []
        }
    },
    methods: {
        getSchedule: function() {
            var vm = this;
            vm.$root.$refs['data-request'].sendPostRequest({
			url: this.action,
			data : {
				'date' : this.startDate, 'length' : this.length 
            }
            })
            .then(data => {
                if(data.status == 'OK') {
                    vm.tasks = data.list;
                } 
            },function() {});
        },
        getOutstanding: function() {
            var vm = this;
            vm.$root.$refs['data-request'].sendGetRequest(this.action + '_complete')
            .then(data => {
                if(data.status == 'OK') {
                    vm.completed = data.list;
                } 
            },function() {});
        },
        mark: function(orderid) {
            var vm = this;
            vm.$root.$refs['data-request'].sendPostRequest({
                url: '/order/mark/' + orderid,
                data: {
                    markas: vm.status
                }
            })
            .then(data => {
                if(data.status == 'OK') {
                    vm.getSchedule();
                    vm.getOutstanding();
                    vm.$emit('statuschange');
                } 
            },function() {});
        }
    },
    mounted: function () {
        this.getSchedule();
        this.getOutstanding();
    },


}
</script>