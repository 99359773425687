<template>
	<f7-page id="account" name="account">
	<f7-navbar>
		<f7-nav-left><f7-link panel-open="left"><f7-icon f7="bars" color="blue"></f7-icon></f7-link></f7-nav-left>
		<f7-nav-title style="text-align:center; width:100%;"><img src="/assets/images/logo.svg" style="height:5rem;" alt="Taylors Embroidery and Print Logo"></f7-nav-title>
		<f7-nav-right>
			<f7-link href="#" text="Add Account" @click="add_account=1; show_account_list=1"></f7-link>
			<!--<f7-menu-item v-if="account != null" href="#" text="Add Address" @click="add_address=1;"></f7-menu-item>-->
			<f7-link v-if="account != null" href="#" text="Save Account Details" @click="save()"></f7-link>
			<f7-link v-if="showDetail" href="#" icon-f7="arrow_2_circlepath" text="" @click="refresh(account.id)"></f7-link>
			<f7-button v-if="account" fill icon-f7="search" @click="account = null">Search for account</f7-button>
		</f7-nav-right>
	</f7-navbar>
	<f7-block >
		<div style="margin-top:6rem;"  v-if="!account">
			<searcher v-if="!account" :controller="'account'"  v-on:clicked="viewAccount($event)"></searcher>
		</div>
		
		<template v-if="account" id="accounts" class="page-content">
			<f7-toolbar tabbar top style="margin-top:0;">
				<f7-link tab-link="#tab-1" tab-link-active>Account Details</f7-link>
				<f7-link tab-link="#tab-2">Garments</f7-link>
				<f7-link tab-link="#tab-3">Artwork</f7-link>
				<f7-link tab-link="#tab-4">Orders</f7-link>
			</f7-toolbar>
			<f7-tabs animated>
				<f7-tab id="tab-1" tab-active>
					<f7-block style="margin-top:4rem; padding:1rem;">
						<h2 style="margin-left:1rem;">Reference: {{ account.account_ref }}</h2>
						<f7-list no-hairlines-md>
							<f7-list-input label="Company/Account name" type="text" placeholder="Company/Account name" v-model:value="account.account_name" :required="true"></f7-list-input>
							<f7-list-input label="Contact name" type="text" placeholder="Contact name" v-model:value="account.contact_name"  :required="true"></f7-list-input>
							<f7-list-input label="Telephone" type="text" placeholder="Telephone" v-model:value="account.telephone"  :required="true"></f7-list-input>
							<f7-list-input label="Email" type="text" placeholder="Email" v-model:value="account.email"  :required="true"></f7-list-input>
							<f7-list-item style="float:right;"><f7-button fill @click="save()">Save</f7-button></f7-list-item>
						</f7-list>
						
						<f7-block  v-if="account && account.address_list.length">
							<h2>Addresses</h2>
							<f7-list>
								<f7-list-item v-for="(line, index) in account.address_list" 
									:key="index" 
									:value="account.address_list[index].id"
									:title="account.address_list[index].formatted_address">
									<template #media>
										<f7-icon f7="trash" @click="deleteAddress(index)" title="delete"></f7-icon>
									</template>
								</f7-list-item>
							</f7-list>
							<f7-button tonal v-if="account && add_address == 0" @click="add_address=1">Add Address</f7-button>
						</f7-block>
						
						<f7-block v-if="add_address">
							<h2>Add Address</h2>
							<addresssearch v-on:updateaddress="addAddress($event)" ></addresssearch>
						</f7-block> 
					</f7-block>
				</f7-tab>
					<f7-tab id="tab-2" >
						<f7-block style="margin-top:4rem; padding:1rem;">
							<div class="data-table" v-if="account.garment_list.length">
								<table>
									<thead>
										<tr>
											<th></th>
											<th>Name</th>
											<th>Customisations</th>
											<th>Related orders</th>
											<th></th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(item, index) in account.garment_list" :key="index">
											<td><img :src="account.garment_list[index].img_path" style="height: 120px" /></td>
											<td>{{ account.garment_list[index].garment_type }}, Created: {{ account.garment_list[index].created_display }}<br><h3>{{ account.garment_list[index].name }}</h3></td>
											<td>
												<div v-for="(art, i) in account.garment_list[index].customer_garment_artwork_placement" :key="i">
												<p>
													<b>Position {{ art.location_number }}</b>: {{ art.customer_artwork.name }}<br>
													<b>Type:</b> {{ art.customer_artwork.artwork_type }}<br>
													<img :src="art.customer_artwork.artwork_preview" style="max-height:40px" />
												</p>
												</div>
											</td>
											<td >
												<span style="padding: 4px; margin:2px; background-color:#AFEEFD;" v-for="(orderid) in account.garment_list[index].related_order_refs" :key="orderid">
													<a :href="'/order/' + orderid">{{ orderid }}</a>
												</span>
											</td>
											<td style="text-align:center;">
												<f7-button small fill icon-f7="trash" v-if="account.garment_list[index].related_order_refs.length == 0" @click="deleteGarment(index)" title="delete">Delete</f7-button>
												<f7-button icon-f7="eye" small  @click="garmentId=item.id; show_garment=true" title="View Garment"></f7-button>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div v-else style="text-align:center"><p>No garments found</p></div>
						</f7-block>
					</f7-tab>
					<f7-tab id="tab-3">	
						<f7-block style="margin-top:4rem; padding:1rem;">
							<div class="data-table" v-if="account.artwork_list.length > 0">
								<table>
									<thead>
										<tr>
											<th></th>
											<th>Type</th>
											<th>Name</th>
											<th>Original Artwork Date</th>
											<th></th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(art, i) in account.artwork_list">
											<td><img :src="art.artwork_preview" :alt="art.id + 'preview'" v-if="art.artwork_preview != null" /></td>
											<td>{{ art.artwork_type }}</td>
											<td>{{ art.name }}</td>
											<td>{{ art.original_artwork_date_formatted }}</td>
											<td>
												<div class="grid grid-cols-2 grid-gap">
												<f7-button small icon-f7="eye"  @click="artId = art.id; open_artwork_viewer=true" title="view"></f7-button> 
												<f7-button small icon-f7="trash"  v-if="art.related_garment_refs.length == 0" href="#" @click="deleteArtwork(i)" title="delete"></f7-button> 
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div v-else><p>No artwork found</p></div>
						</f7-block>
					</f7-tab>					
					<f7-tab id="tab-4">	
						<f7-block style="margin-top:4rem; padding:1rem;">
							<div v-if="account.order_list.length"><dashtable  v-model="account.order_list" hideButtons="1"></dashtable></div>
							<div v-else style="text-align:center"><p>No orders found</p></div>
						</f7-block>
					</f7-tab>		
				</f7-tabs>		

		</template>
	</f7-block>
		<f7-panel :opened="show_garment" class="color-theme-blue" style="background-color: #eee;" :close-by-backdrop-click="true" :backdrop="false" v-on:panel:closed="show_garment=false" >
            <garment v-if="show_garment"  
			:garmentId="garmentId"
			:accountId="account.id"
			/>
			<f7-button style="margin-top:1rem;" fill @click="show_garment = false">Close</f7-button>
        </f7-panel>
		
		<f7-panel :opened="open_artwork_viewer" class="color-theme-blue" style="background-color: #eee;" :close-by-backdrop-click="true" :backdrop="false" v-on:panel:closed="open_artwork_viewer=false" >
            <artwork v-if="open_artwork_viewer" :label="account.account_name" 
			:artworkId="artId"
			:accountId="account.id"
			></artwork>
			<f7-button style="margin-top:1rem;" fill @click="open_artwork_viewer = false">Close</f7-button>
        </f7-panel>
    </f7-page>
</template>
<style scoped>


.card-header {
	height: 240px;
}
.date {
	font-size: 0.8rem;
}
.page-content {
	padding: 1rem 0;
}
</style>
<script>
import AccountList from './../components/account-list.vue';
import AddressSearch from './../components/address-search.vue';
import Artwork from './../components/artwork.vue';
import Garment from '../components/garment.vue';
import DashBoardTable from './../components/dashboard-table.vue';
import Searcher from '../components/searcher.vue';
export default {
	components: {
        accountlist: AccountList,
		addresssearch: AddressSearch,
		artwork: Artwork,
		garment: Garment,
		dashtable : DashBoardTable,
		searcher: Searcher
    },
	data() {
		return { 
			show_account_list: 0,
			accounts : [],
			add_account: 0,
			account: null,
			add_address: 0,
			open_artwork_viewer: false,
			show_garment: false,
			artId: null,
			garmentId: null
		}
	},
	methods: {
		/*fetchAll: function() {
			var vm = this;
			vm.$root.$refs['data-request'].sendGetRequest('/account/list')
			.then(data => {
				vm.accounts = data.list;
				vm.add_account = 0;
				vm.show_account_list = 0;
				vm.open_artwork_viewer = 0;
			}, function() {});
		},
		refresh: function(id) {
			console.log('id ' + id);
			this.account = null;
			delete this.accounts[id].detail;
			this.viewAccount(id);
		},*/
		viewAccount: function (id) {
		
			if(!this.accounts.hasOwnProperty(id)) {
				var vm = this;
				vm.$root.$refs['data-request'].sendGetRequest('/account/detail/' + id)
				.then(data => {
					if(data.status == 'OK') {
						vm.accounts[id] = data.account;
						vm.account = vm.accounts[id];
					} 
				}, function() {});				
			} 
			
		},
		addAddress: function (newaddress) {
			//console.log(newaddress);
			if(typeof newaddress === 'undefined') {
				this.add_address = 0;
				return;
			}
			var vm = this;
         	Rvm.$root.$refs['data-request'].sendPostRequest({
			url: '/account/saveaddress/',
			data : {
				id : this.account.id,
				address : newaddress
			}
			}).then(data => {
				if(data.status == 'OK') {
					vm.add_address = 0;
					vm.refresh(vm.account.id);
				} 
			}, function() {});
		},
		save: function() {
			var vm = this;

			vm.$root.$refs['data-request'].sendPostRequest({
			url: '/account/save/',
			data : {
				account: {
					id : this.account.id, account_name : this.account.account_name, contact_name : this.account.contact_name, telephone : this.account.telephone, email : this.account.email
				}
			}
			}).then(data => {
				if(data.status == 'OK') {
					alert('Changes saved');
				} else {
					alert('An error occured, please try again');
				}
			}, function() {});  
		},
		deleteGarment: function(index) {
			if(!confirm('delete garment ' + this.account.garment_list[index].name + ' are you sure?')) {
				return;
			}
			var vm = this;
			vm.$root.$refs['data-request'].sendGetRequest('/garment/delete/' + this.account.garment_list[index].id)
			.then(data => {
				if(data.status == 'OK') {
					vm.account.garment_list.splice(index,1)
				} else {
					alert('An error occured, please try again');
				}
			}, function() {}); 
		},
		deleteArtwork: function(index) {
			if(!confirm('delete artwork ' + this.account.artwork_list[index].name + ' are you sure?')) {
				return;
			}
			var vm = this;
			vm.$root.$refs['data-request'].sendGetRequest('/artwork/delete/' + this.account.artwork_list[index].id)
			.then(data => {
				if(data.status == 'OK') {
					vm.account.artwork_list.splice(index,1)
				} else {
					alert('An error occured, please try again');
				}
			}, function() {}); 
		},
		deleteAddress: function(index) {
			if(!confirm('delete address are you sure?')) {
				return;
			}
			var vm = this;
			vm.$root.$refs['data-request'].sendGetRequest('/account/deleteaddress/' +  this.account.address_list[index].id)
			.then(data => {
				if(data.status == 'OK') {
					vm.account.address_list.splice(index,1)
				} else {
					alert('An error occured, please try again');
				}
			}, function() {}); 
		}
	},
	mounted: function() {
		this.viewAccount(1);
	},
	computed: {
		showDetail: function() {
			if(this.account != null && this.account.hasOwnProperty('detail')) {
				return true;
			}
			return false;
		}
	}
}
</script>

