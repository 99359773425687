<template>
    <tr  v-if="!modelValue.delete">
        <td>
			<a class="icon-button color2" v-if="can_edit_order" href="#" @click="deleteLine()" title="delete line"><f7-icon f7="trash"></f7-icon></a> 
        </td>
        <td><p>{{modelValue.garment_type}}</p></td>
        <td  v-if="can_edit_order">
            <input type="text" style="min-width:280px;" v-model="modelValue.sku" @input="$emit('update:sku', $event.target.value)"  placeholder="sku" />
            <textarea ref="description" v-model="modelValue.description" @input="$emit('update:desc', $event.target.value)" placeholder="add description" />
        </td>
        <td v-else>
            <p>{{ modelValue.sku }}<br>
                {{ modelValue.description }}
            </p>
        </td>
        <td class="numeric-cell">
            <input type="number" ref="qty" v-model="modelValue.quantity" @input="getCosts()" :disabled="!can_edit_order" />
        </td>
        <td class="numeric-cell"><p>{{modelValue.unit_cost}}</p></td>
        <td class="numeric-cell"><p>{{modelValue.line_cost}}</p></td>
        <td class="numeric-cell"><input type="number" ref="price" v-model="modelValue.unit_price" @input="$emit('update:lineprice', ($event.target.value * modelValue.quantity).toFixed(2))" :disabled="!can_edit_order" /></td>
        <td class="numeric-cell"><p>{{modelValue.line_price}}</p></td>
        <td v-if="modelValue.customer_garment.id == null"><p><f7-button class="color-pink button-small button-fill" @click="$emit('addcustomisation', $event);">Customise</f7-button></p></td>
        <td v-else><p>
            <f7-chip :text="modelValue.customer_garment.name" :deleteable="can_edit_order" @delete.prevent="$emit('deletecustomisation', $event);"></f7-chip>
            <a href="#" @click.prevent="$emit('addcustomisation', $event);" style="padding:0.5rem;">view</a>
            </p> 
        </td>
        <td><input type="number" :disabled="!can_edit_order" v-model="modelValue.customisation_price" @input="$emit('update:customisationprice', $event.target.value)" /></td>
        <td class="numeric-cell"><p>{{ modelValue.line_total }}</p></td>
    </tr>
</template>
<style scoped>
input[type=number] {
    float: right;
    text-align: right;
    border: solid 1px #eee;
    background-color: #FEF9E7;
}

</style>
<script>

export default {
    props: ['modelValue', 'can_edit_order'],
    emits: ['updaterow', 'update:lineprice', 'update:customisationprice', 'update:desc', 'addcustomisation'],
    data: function () {
        return {
            info: []
        }
    },
    methods: {
        getCosts: function() {
            if(this.modelValue.sku != 'n/a' && this.modelValue.sku != 'ext') {
                var vm = this;
                this.info = '';
                vm.$root.$refs['data-request'].sendPostRequest({
                url: '/product/calculatecost/',
                data : {
                    'sku' : this.modelValue.sku, 'quantity' : this.modelValue.quantity
                }
                })
                .then(data => {
                    if(data.status == 'OK') {
                        this.$emit('updaterow', {'unit_cost': data.unit_cost, 'line_cost' : data.line_cost, 'suggested_unit_price' : data.suggested_unit_price, 'suggested_line_price' : data.suggested_line_price});
                    } 
                }, function() {});    
            } else {
                //need to emit a new cost to update row!
                this.$emit('updaterow', {'unit_cost': 0, 'line_cost' : 0, 'suggested_unit_price' : 0, 'suggested_line_price' : 0});
            }
        },
        deleteLine: function() {
            if(confirm('delete ' + this.modelValue.garment_type + ' are you sure?')) {
                this.$emit('deleterow');
            }
        }
    },
    mounted: function () {
        this.$nextTick(() => {
            if(!this.modelValue.description) {
                this.$refs.description.select();
            } else {
                this.$refs.qty.select();
            }
        }) 
    },
}
</script>