<template>
<div>
	 	<input type="file" accept=".png,.jpg,.jpeg,.gif,.pdf" @change="upload">
		<p v-if="error != ''" class="error">{{ error }}</p>
</div>
</template>
<script>
    export default {
		emits: ['save-state'],
		data: function() {
			return {
				error: '',
				uploaded : {
					filename : null,
					contentType : null,
					content : null		
				}
			};
		},
		methods: {
			upload: function(e) {
				this.error = '';
				var files = e.target.files || e.dataTransfer.files;
				if (!files.length) {
					return false;
				}

				var extensions = ['png', 'jpg', 'jpeg', 'gif', 'pdf', 'dxf', 'dwg', 'iges', 'stp', 'step', 'sldprt', 'sldasm', 'svg'];
				var ext_re = /(?:\.([^.]+))?$/;
				var extension = ext_re.exec(files[0].name)[1].toLowerCase();

				if(!extensions.includes(extension)) {
					this.error = 'Not a valid file type';
					$(e.target).val('');
					return false;
				}			

				if(files[0].size > 12000000) {
					this.error = 'The file is too big';
					$(e.target).val('');
					return false;
				}

				var reader = new FileReader();
				var self = this;
				this.uploaded.filename = files[0].name;
				this.uploaded.contentType = files[0].type;
				reader.onload = (e) => {
					self.uploaded.content = e.target.result;
					self.$emit('save-state', self.uploaded);
					$(e.target).val('');
				}
				reader.readAsDataURL(files[0]);
			},/*
			remove: function() {
				this.uploaded.filename = null;
				this.uploaded.contentType = null;
				this.uploaded.content = null;
				this.$emit('save-state', self.uploaded);
			}*/
		}
    };
</script>