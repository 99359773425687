<template>
    <f7-page id="order-page" name="order">
        <f7-navbar>
            <f7-nav-left><f7-link panel-open="left"><f7-icon f7="bars" color="blue"></f7-icon></f7-link></f7-nav-left>
            <f7-nav-title style="text-align:center; width:100%;"><img src="/assets/images/logo.svg" style="height:5rem;" alt="Taylors Embroidery and Print Logo"></f7-nav-title>
        </f7-navbar>

        <f7-block v-if="order != null" style="margin-top:4rem;">
            <div class="main-menu grid grid-cols-6 grid-gap" >
                <f7-actions v-model:opened="show_menu" >
                    <f7-actions-label>Order Options</f7-actions-label>
                    <div>
                        <f7-actions-button v-if="order && order.id" @click="emailOrder()">
                            <f7-icon f7="envelope"></f7-icon>
                            Email {{ approval_required == 1 ? 'Quote' : 'Confirmation' }}
                        </f7-actions-button>
                    </div>
                    <div>
                        <f7-actions-button v-if="order && order.id && !order.run_schedule.id" @click="showMiniScheduler=true">
                            <f7-icon f7="calendar"></f7-icon>
                            Set Run Schedule
                        </f7-actions-button>
                    </div>
                    <div>
                        <f7-actions-button v-if="order && order.id" @click="emailOther('dates')">
                            <f7-icon f7="envelope"></f7-icon>
                            Email Schedule
                        </f7-actions-button>
                    </div>
                    <div>
                        <f7-actions-button v-if="order && order.id">
                            <f7-link small :href="'/delivery-note/' + order.id" :target="'_blank'" :reload-all="true" :external="true" title="Print Delivery Note" icon-f7="doc_text">Print Delivery Note</f7-link>
                            
                        </f7-actions-button>
                    </div>
                    <div>
                        <f7-actions-button v-if="order.id" @click="show_store=true">
                            <f7-icon f7="folder_badge_plus"></f7-icon>
                            View Store
                        </f7-actions-button>
                    </div>
                    <div>
                        <f7-actions-button v-if="order && order.id" @click="show_order_notes = 1">
                            <f7-icon f7="sticker"></f7-icon>
                            Notes
                        </f7-actions-button>
                    </div>
                    <div>
                        <f7-actions-button outline v-if="order && order.id" @click="duplicate()">
                            <f7-icon f7="rectangle_on_rectangle"></f7-icon>
                            Duplicate Order
                        </f7-actions-button>
                    </div>
                    <div>
                        <f7-actions-button outline  v-if="order && order.id" @click="deleteOrder()">
                            <f7-icon f7="trash"></f7-icon>
                            Delete Order
                        </f7-actions-button>
                    </div>

                </f7-actions>
                <div>
                    <f7-button fill v-if="order && order.id" href="#" text="Menu" icon-f7="plus" @click="show_menu=true;" />
                </div>
                <div></div>
                <div></div>
                <div>
                    <f7-button tonal  @click="add_account=1; show_account_list=1">
                        <f7-icon f7="building"></f7-icon>
                        Choose Customer
                    </f7-button>
                </div>
                <div>
                    <f7-button fill v-if="order && order.account.id && can_edit" href="#" text="Add Order Line" icon-f7="plus" @click="show_product_finder=1;" />
                </div>
                <div>
                    <f7-button outline v-if="order && order.account.id  && can_edit" href="#" icon-f7="floppy_disk" @click="save()" text="Save Order" />
                </div>
            </div>
        </f7-block>  

        <f7-block v-else style="margin-top:4rem;">
            <div class="grid grid-cols-4 large-grid-cols-8 grid-gap">
                <div><f7-button outline href="#" text="New Order" @click.prevent="newOrder()"></f7-button></div>
                <div><f7-button outline href="#" :text="'Email ' + (approval_required == 1 ? 'Quote' : 'Order Confirmation')" @click="emailOrder()"></f7-button></div>
            </div>
        </f7-block> 
        <f7-block v-if="order != null && order.account.id" class="no-top-margin no-bottom-margin">
            <f7-block-title>
                <h2>{{ order.account.account_ref }} {{ order.account.account_name }}</h2>
                <p v-html="order.delivery_address"></p>
            </f7-block-title>
            <div class="grid grid-cols-3 grid-gap">
                <div>
                    <f7-list inline-labels no-hairlines-md v-if="order.account">
                        <f7-list-input label="Job Name" type="text" :placeholder="'job name'" v-model:value="order.job_name" :disabled="!can_edit" inputStyle="font-size:1.2rem; text-transform:uppercase; font-weight:bold;" ></f7-list-input>
                    </f7-list>    
                    <autosearch labelText="Despatch Method" url="/schedule/get_dispatch_methods" minchars="1" param="" v-model:value="order.despatch_method" v-on:refine="order.despatch_method = $event.code" position="relative"></autosearch>
                  
                </div>
                <div>
                    <f7-list inline-labels no-hairlines-md v-if="order.account">
                        <f7-list-input label="Contact name" type="text" placeholder="contact name" v-model:value="order.contact_name" :disabled="!can_edit"></f7-list-input>
                        <f7-list-input label="Telephone" type="text" placeholder="contact telephone" v-model:value="order.contact_telephone" :disabled="!can_edit"></f7-list-input>
                        <f7-list-input label="Email" type="text" placeholder="contact email" v-model:value="order.contact_email" :disabled="!can_edit"></f7-list-input>
                        <f7-list-input label="Customer Ref / PO" type="text" placeholder="customer reference" v-model:value="order.customer_reference" :disabled="!can_edit"></f7-list-input>
                        
                    </f7-list>
                </div>               
                <div class="right">
                    Date: {{ order.created_display }}<br>      
                    <div v-if="order.id">                    
`                        <span>Order Reference: <b>{{ order.id }}</b></span><br>
                        <span>Order Status: <b>{{ order.status }}</b></span>
                        <p v-if="can_edit">Quote Approval required: <f7-toggle v-model:checked="approval_required" /></p>
                        <p>Garment Status: <b>{{ order.garment_order_status }} {{ order.garment_order_date_display }}</b></p>
                        <p v-if="order.run_schedule.date">Run Date: <b>{{ order.run_schedule.date_display }}</b></p>
                        <p v-if="order.dispatch_schedule.date">Despatch Date: <b>{{ order.dispatch_schedule.date_display }}</b></p>`
                    </div>   
                </div>                                 
            </div>
        </f7-block>
        <f7-block v-if="order != null && order.account.id">
            <div class="data-table card">
                    <table>
                    <thead>
                        <tr>
                            <th></th>
                            <th width="">Type</th>
                            <th>Sku/Description</th>
                            <th class="numeric-cell">Quantity</th>
                            <th class="numeric-cell">Garment<br>Unit Cost</th>
                            <th class="numeric-cell">Garment<br>Line Cost</th>
                            <th class="numeric-cell">Garment<br>Unit Price</th>
                            <th class="numeric-cell">Garment<br>Line Price</th>
                            <th>Customisation</th>
                            <th class="numeric-cell">Customisation<br>Price</th>
                            <th class="numeric-cell">Line Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(line, index) in order.order_details" v-bind:key="index">
                            <orderline  v-model="order.order_details[index]" 
                            v-on:addcustomisation="openConfigurator(index)" 
                            v-on:deletecustomisation="deleteCustomisation(index)" 
                            v-on:updaterow="updateRow($event, index)" 
                            v-on:update:lineprice="order.order_details[index].line_price= $event; calcLine(index);" 
                            v-on:update:customisationprice="order.order_details[index].customisation_price = $event; calcLine(index);" 
                            v-on:deleterow="deleteRow(index)" 
                            v-on:update:desc="updateDesc($event, index)" 
                            v-on:update:sku="updateSku($event, index)" 
                            :can_edit_order="can_edit"></orderline>
                        </template>
                    </tbody>
                    </table>
                </div>
                     
            </f7-block>
        <f7-block v-if="order != null && order.account.id">
            <div class="grid large-grid-cols-3 grid-gap">
                <div></div>
                <div></div>
                <div>
                    <f7-list inline-labels no-hairlines-md v-if="order.account" style="margin:0">
                        <f7-list-input label="Carriage £" type="number" placeholder="carriage" v-model:value="order.carriage" :disabled="!can_edit"></f7-list-input>
                        <f7-list-input label="Setup Cost £" type="number" placeholder="setup cost" v-model:value="order.setup" :disabled="!can_edit"></f7-list-input>
                        <f7-list-input label="Sub Total £" type="number" readonly="true" v-model:value="subtotal_formatted"></f7-list-input>
                        <f7-list-input label="VAT £" type="number" readonly="true"  v-model:value="vat_formatted"></f7-list-input>
                        <f7-list-input label="Total £" type="number" readonly="true" v-model:value="ordertotal_formatted"></f7-list-input>
                    </f7-list>
                </div>
            </div>
        </f7-block>
        <!--
        <f7-panel v-model:opened="show_product_finder" class="color-theme-pink" style="background-color: #eee;" close-by-backdrop-click="true" backdrop="true" v-on:panel:closed="show_product_finder=0;">
            <div v-if="show_product_finder">
                <productfinder v-on:addproduct="updateOrder($event)" v-on:closepanel="show_product_finder=0"></productfinder>
            </div>
        </f7-panel>-->
        <f7-panel v-model:opened="show_product_finder" 
            v-on:panel:closed="show_product_finder=false;" 
            class="color-theme-pink" style="background-color: #eee;" :close-by-backdrop-click="true" :backdrop="true"
            right  cover container-el="#order-page"
            >
            <div v-if="show_product_finder">
            <productchooser
                v-on:closepanel="show_product_finder=false" 
                v-on:chosenProduct="updateOrder($event)" 
                 />
            </div>
        </f7-panel>
        <f7-panel v-model:opened="show_garment_configurator" 
            v-on:panel:closed="show_garment_configurator=false;" 
            class="color-theme-pink" style="background-color: #eee;" :close-by-backdrop-click="true" :backdrop="true"
            right  cover container-el="#order-page"
            >
            <div v-if="show_garment_configurator">
            <garmentchooser
                v-on:closepanel="show_garment_configurator=false" 
                :account-id="order.account_id" 
                :garment-id="order.order_details[current_row_index].garment_id" 
                :garment-type="order.order_details[current_row_index].garment_type" 
                v-on:chosenGarment="addGarment($event)" 
                :orderId="orderId"
                 />
            </div>
        </f7-panel>
        <f7-panel v-model:opened="show_store" v-on:panel:closed="show_store=false" class="color-theme-pink" style="background-color: #eee;" :close-by-backdrop-click="true" :backdrop="true"
        right  cover container-el="#order-page">
        <div v-if="show_store">
                <filestore 
                    :orderId="order.id"
                    v-on:closepanel="show_store=false" 
                />
        </div>
        </f7-panel>   
        <f7-panel v-model:opened="show_account_list" v-on:panel:closed="show_account_list=false" class="color-theme-pink" style="background-color: #eee;" :close-by-backdrop-click="true" :backdrop="true">
            <div v-if="show_account_list">
                <accountlist v-on:selectaccount="updateAccount($event)" v-on:closepanel="show_account_list=0"></accountlist>
            </div>
        </f7-panel>        
        <f7-panel :opened="show_order_notes" v-on:panel:closed="show_order_notes=0" class="color-theme-pink" style="background-color: #eee;" close-by-backdrop-click="true" backdrop="true">
            <div v-if="show_order_notes">
                <logviewer :orderid="order.id" v-on:closepanel="show_order_notes=0"></logviewer>
            </div>
        </f7-panel> 


        <minischeduler v-if="showMiniScheduler" :OrderId="order.id" v-on:newJob="updateSchedule($event)" v-on:popup:closed="hideMiniScheduler()" />
   </f7-page>


   
</template>

<script>
import {  f7 } from 'framework7-vue';
import AutoSearch from './../components/auto-search.vue';

import OrderLine from './../components/order_line.vue';
import GarmentChooser from './../components/garment-chooser.vue';
import AccountList from './../components/account-list.vue';
import LogViewer from '../components/log-viewer.vue';
import ProductChooser from '../components/product-chooser.vue';
import FileStore from '../components/file-store.vue';
import MiniScheduler from '../components/mini-scheduler.vue';

export default {
    components: {
        autosearch : AutoSearch,
        minischeduler: MiniScheduler,
        orderline: OrderLine,
        garmentchooser: GarmentChooser,
        accountlist: AccountList,
        logviewer: LogViewer,
        productchooser: ProductChooser,
        filestore: FileStore
    },
    props: {
        orderId: Number
    },
    data: function () {
        return {
            show_product_finder: 0,
            show_garment_configurator: false,
            show_order_notes: 0,
            show_store: false,
            can_edit_garment: 1,
            show_account_list: false,
            current_row_index: 0,
            enabled : 1,
            order: null,
            messages: [],
            approval_required: true,
            show_menu: false,
            showMiniScheduler:false
        }
    },
    methods: {
        newOrder: function() {
            window.location.reload(); 
        },
        updateOrder: function(item) {
            console.log('updating order', item);
            var vm = this;
            vm.$root.$refs['data-request'].sendGetRequest('/order/newline/' )
            .then(response => {
                response.data.id = 0;
                response.data.sku = item.sku;
                response.data.description = item.style;
                response.data.garment_source = item.source;
                response.data.garment_type = item.type.name;
                response.data.garment_id = null;
                /*
                if(item.style) {
                    response.data.description = item.style;
                } else {
                    if(item.sku == 'ext') {
                        response.data.description = 'External';
                    } else if(item.sku == 'n/a') {
                        response.data.description = 'Customer Supplied';
                    }
                }
                
                switch(item.sku) {
                    case 'n/a':
                        response.data.garment_source = 'Customer';
                        break;
                    case 'ext':
                        response.data.garment_source = 'External';
                        break;
                    default: 
                        response.data.garment_source = 'Taylors';

                }*/
                
                
                vm.order.order_details.push(response.data);
                vm.current_row_index = vm.order.order_details.length -1;
            }, function(){});
        },
        deleteCustomisation: function(index) {
            if(confirm('Remove customisation ' + this.order.order_details[index].garment_name + ' ?')) {
                var vm= this;
                vm.$root.$refs['data-request'].sendGetRequest('/garment/new/')
                .then(data => {
                    if(data.status == 'OK') {
                        vm.order.order_details[index].customer_garment = data.garment;  //set to blank
                        vm.order.order_details[index].garment_id = null;
                        vm.show_garment_configurator = false;
                    } 
                }, function(){}); 
               
            }
        },
        deleteRow: function(index) {
            this.order.order_details[index].delete = 1;
        },
        updateAccount: function(item) {
            this.order.account = item.account;
            this.order.delivery_address = item.address;
            this.order.contact_name = item.account.contact_name;
            this.order.contact_telephone = item.account.telephone;
            this.order.contact_email = item.account.email;
            this.order.account_id = item.account.id;
            this.show_account_list = 0;
        },                
        updateRow: function (event, index) {
            if(this.order.order_details[index].unit_price == 0) {
                //don't add suggested values if user has entered a price
                this.order.order_details[index].unit_cost = event.unit_cost;
                this.order.order_details[index].line_cost = event.line_cost;
                this.order.order_details[index].unit_price = event.suggested_unit_price;
                this.order.order_details[index].line_price = event.suggested_line_price;
            } else {
                this.order.order_details[index].line_cost = (this.order.order_details[index].unit_cost * this.order.order_details[index].quantity).toFixed(2);
                this.order.order_details[index].line_price = (this.order.order_details[index].unit_price * this.order.order_details[index].quantity).toFixed(2);
            }

            this.calcLine(index);
        },
        updateDesc: function(event, index) {
            this.order.order_details[index].description = event;
        },
        updateSku: function(event, index) {
            this.order.order_details[index].description = event;
        },
        addGarment: function (event) {
            console.log(event);
            this.order.order_details[this.current_row_index].customer_garment = event;
            this.order.order_details[this.current_row_index].garment_id = event.id;
        },
        openConfigurator: function (index) {
            this.current_row_index = index;
            this.show_garment_configurator = true;
        },
        calcLine: function (index) {
            this.order.order_details[index].line_total = (parseFloat(this.order.order_details[index].line_price) + 
            (parseFloat(this.order.order_details[index].customisation_price) * parseFloat(this.order.order_details[index].quantity))).toFixed(2);

            //this.order.subtotal = this.order.order_details.reduce((accumulator, currentValue) => accumulator + currentValue.line_total,  0)
        },
        validate: function(_callback) {
            var valid = false;
            this.messages = [];
            if(!this.order.account.id) {
                this.messages.push('Customer Account not set');
            }
            if(!this.order.job_name) {
                this.messages.push('Please enter a job name');
            }
            if(this.order.order_details.length == 0) {
                this.messages.push('At least one orderline is required');
            }
            if(this.messages.length){
                f7.dialog.alert(this.messages.join(), "Missing Information");
            }

            _callback(this.messages.length ? false : true);
        },
        save: function() {
            var vm = this;
            this.validate(function(valid) {
                if(valid) {
                    vm.order.subtotal = vm.subtotal;
                    vm.order.vat = vm.vat;
                    vm.order.total = vm.ordertotal;
                    vm.$root.$refs['data-request'].sendPostRequest({
                        url: '/order/save/',
                        data : vm.order
                    })
                    .then(data => {
                        if(data.status == 'OK' && data.orderref) {
                            vm.enabled = data.order_status == 'Quote';
                            vm.order.id = data.orderref;
                            vm.order.status = data.order_status;
                            vm.loadOrder(data.orderref);
                            f7.dialog.alert('Order ' + vm.order.id + ' saved', "Success");
                        } 
                    }, function(){});  
                } 
            });              
        },
        emailOrder: function() {
            var vm = this;
            vm.$root.$refs['data-request'].sendPostRequest({
                url: '/order/send_email/' + this.order.id,
                data : {'approval_required' : this.approval_required}
            })
            .then(data => {
                if(data.status == 'OK') {
                    f7.dialog.alert('Email sent', "Success");
                    vm.order.status = data.order_status;
                } else {
                    f7.dialog.alert('Email could not be sent!', "Error");
                }
            }, function(){});  
        },
        emailOther: function(topic) {
            var vm = this;
            vm.$root.$refs['data-request'].sendPostRequest({
                url: '/order/send_email_update/' + this.order.id,
                data : {'topic' : topic}
            })
            .then(data => {
                if(data.status == 'OK') {
                    f7.dialog.alert('Email sent', "Success");
                    vm.order.status = data.order_status;
                } else {
                    f7.dialog.alert('Email could not be sent!', "Error");
                }
            }, function(){});  
        },        
        duplicate: function() {
            var vm = this;
            var id = this.order.id;
            vm.$root.$refs['data-request'].sendGetRequest('/order/duplicate/' + this.order.id)
            .then(data => {
                if(data.status == 'OK' && data.order) {
                    vm.order = data.order;
                    vm.show_product_finder = 0;
                    vm.show_garment_configurator =false;
                    vm.show_order_notes = 0;
                    vm.can_edit_garment = 1;
                    vm.show_account_list = false;
                    vm.current_row_index = 0;
                    vm.enabled = 1;
                    vm.approval_required = true
                    vm.messages.push('This is a copy of order ' + id + ', make any changes and save to get a new order reference.');
                } else {
                    f7.dialog.alert('An error occured!', "Error");
                }
            }, function(){}); 
        },   
        deleteOrder: function() {
            if(!confirm('Delete order ' + this.order.id + ' are you sure?')) {
                return;
            }
            var vm = this;

            vm.$root.$refs['data-request'].sendGetRequest('/order/delete/' + this.order.id)
            .then(vm.loadOrder(), function(){});
        },
        loadOrder: function(id)     {
            var vm = this;
            vm.$root.$refs['data-request'].sendGetRequest('/order/load/' + id)
            .then(data => {
                vm.order = data.order;
                //vm.order.account.id = 'REND000001';
            }, function(){});
        }, 
        newOrder: function()     {
            var vm = this;
            vm.$root.$refs['data-request'].sendGetRequest('/order/new')
            .then(data => {
                vm.order = data.order;
                vm.show_account_list=true;
            })
        },
		updateSchedule: function(event) {
			this.order.run_schedule = {...event};
		},
    },
    mounted : function() {
        this.order = null;
        if(this.orderId !== undefined) {
            this.loadOrder( this.orderId);
        } else {
            this.newOrder();
        }
    },
    computed: {
        subtotal: function() {
            if(!this.order ||this.order.order_details.length == 0) 
                return 0;

            var runningtotal = 0;
            this.order.order_details.forEach(function (line) {
                runningtotal += parseFloat(line.line_total);
            });
            return parseFloat(runningtotal) + (this.order.setup == null ? 0 : parseFloat(this.order.setup)) + (this.order.carriage == null ? 0 : parseFloat(this.order.carriage));
        },
        subtotal_formatted() {
            return this.subtotal.toFixed(2);
        },
        vat: function() {
            return parseFloat(this.subtotal) * 0.2;

        },
        vat_formatted() {
            return this.vat.toFixed(2);
        },        
        ordertotal: function() {
            return this.subtotal  + this.vat;
        },
        ordertotal_formatted() {
            return this.ordertotal.toFixed(2);
        },     
        can_edit: function () {
            //can only edit garment in new stage
            //if( this.order.status == 'Quote' || this.order.status == 'New' || this.order.status == null){
                return true;
            //}
            //return false;
        }           
    }       
}
</script>

