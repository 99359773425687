<template>
    <f7-popup :backgroupUnique="true" :opened="isOpen" >
        <f7-page>
        <f7-navbar title="Schedule Despatch">
            <f7-nav-right>
            <f7-link popup-close>Close</f7-link>
            </f7-nav-right>
        </f7-navbar>

        <f7-block v-if="order" style="margin-top:4rem;">
                <h3>Order Ref: {{ order.id }}<br>
                {{ order.account.account_name }}: {{ order.job_name }}</h3>
                <f7-list no-hairlines-md  style="margin-top:0;margin-bottom:0;">
                    <f7-list-input label="Despatch date" type="date" v-model:value="despatch.date">
                    </f7-list-input>

                    <f7-list-input label="Notes" type="text" v-model:value="despatch.notes">
                    </f7-list-input>                                                                
                </f7-list>
                <f7-button fill style="margin: 4px;"  v-if="despatch.date"  @click="saveSchedule()">Set Despatch Date</f7-button>
            </f7-block>
        </f7-page>
    </f7-popup>
</template>

<script>


import {  f7 } from 'framework7-vue';
export default {
	props: [ 'OrderId', 'adhoc' ],
    emits:['setDespatch', 'closeMe'],
	data() {
		return {
            order: null,
            isOpen: false,
            despatch: {
                order_id: null,
                date: null,
                notes: null,
            }
		}
	},
	methods:  {
		saveSchedule: function() {
			
				var vm = this;
				vm.$root.$refs['data-request'].sendPostRequest({
					url: '/scheduler/schedule_dispatch',
					data : vm.despatch
				})			
				.then(data => {
					if(data.status == 'OK') {
                        
                        vm.$emit('setDespatch', data.item);
                        vm.hideMe();
					} 
				}, function(){});
			
		},
		hideMe() {
			this.order = null;
            this.isOpen = false;
		},
		getOrder() {
            var vm = this;
            vm.$root.$refs['data-request'].sendGetRequest('/order/load/' + this.OrderId)
            .then(data => {
                if(data.status == 'OK') {
                    vm.order = data.order,
                    vm.despatch.order_id = data.order.id,
                    vm.despatch.date = data.order.dispatch_schedule.date,
                    vm.despatch.notes =  data.order.dispatch_schedule.notes
                } 
            },function() {});
		}
	},
	mounted: function() {
        this.getOrder();
        this.despatch.date = this.today;
        this.isOpen = true;
	},
	computed: {
		today: function() {
			var today = new Date();
			var dd = String(today.getDate()).padStart(2, '0');
			var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
			var yyyy = today.getFullYear();

			return yyyy + '-' + mm + '-' + dd;
		},


	}
}
</script>
