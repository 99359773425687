<template>
	<!-- Main Framework7 App component where we pass Framework7 params -->
	<f7-app v-bind="f7params">

		<f7-panel left cover theme-dark>
			<f7-view>
			<f7-page>
				<f7-navbar title="Menu"></f7-navbar>
				<!--<f7-block-title>Left View Navigation</f7-block-title>
				<f7-list>
				<f7-list-item link="/left-page-1/" title="Left Page 1"></f7-list-item>
				<f7-list-item link="/left-page-2/" title="Left Page 2"></f7-list-item>
				</f7-list>-->
				<f7-block-title>Menu</f7-block-title>
				<f7-list>
				<f7-list-item link="/dashboard/" view=".view-main" panel-close title="Dashboard" :reload-all="true"></f7-list-item>	
				<f7-list-item link="/account/" view=".view-main" panel-close title="Accounts" :reload-all="true"></f7-list-item>
				<f7-list-item link="/catalogue/" view=".view-main" panel-close title="Garment Catalogue" :reload-all="true"></f7-list-item>
				<f7-list-item link="/order" view=".view-main" panel-close title="Create Order" :reload-all="true"></f7-list-item>
				<f7-list-item link="/schedule/" view=".view-main" panel-close title="Schedule" :reload-all="true"></f7-list-item>
				<f7-list-item link="/garment-order/" view=".view-main" panel-close title="Garment Order List" :reload-all="true"></f7-list-item>
				<!--<f7-list-item link="#" view=".view-main" back panel-close title="Back in history"></f7-list-item>-->
				</f7-list>
			</f7-page>
			</f7-view>
		</f7-panel>
		<f7-view main class="safe-areas" url="/" :browser-history="true" browser-history-separator="">
			<ajaxmodule ref="data-request"></ajaxmodule>
		</f7-view>
		
	</f7-app>
</template>

<script>
	import { ref, onMounted, nextTick } from 'vue';
	import { f7, f7ready } from 'framework7-vue';
	import routes from '../js/routes';
	import AjaxModule from './../components/ajax-module.vue'


	export default {
		components: {
			ajaxmodule: AjaxModule
    	},	
    setup() {
        onMounted(() => {
            f7ready(() => {
                // Call F7 APIs here
            });
        });
        return {
            f7params: {
                name: "Taylors",
                id: "com.rendili.taylors",
                theme: "auto",
                panel: {
                    swipe: true,
                },
                routes: routes
                // ... other params
            },
        };
    },
};
</script>
