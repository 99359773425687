<template>
    <f7-page name="home">
        <div class="card">
            <div class="card-content" style="text-align: center; padding:3rem;">
                <h1 style="text-align:center; width:100%;"><img src="/assets/images/logo.svg" style="height:8rem;" alt="Taylors Embroidery and Print Logo"></h1>
                <p style="text-align: center; padding:3rem;"><a class="button link button-fill" style="width:6rem; margin:auto; background:#005776;" href="/dashboard/">Login</a></p>
            </div>
        </div>
    </f7-page>
</template>
<script>
export default  {}
</script>
