var RendiliCore = {
	Settings: {
		OnUnauthorized: null,
		OnForbidden: null
	},
	Json: {
		Get: function(parameters) {
			return RendiliCore._base('GET', parameters, 'json');

		},
		Post: function(parameters) {
			return RendiliCore._base('POST', parameters, 'json');
		},
		PostJsonData: function(parameters) {
			if (typeof parameters == 'string') {
				parameters = { url: parameters };
			}
			parameters.data = JSON.stringify(parameters.data);
			return RendiliCore._base('POST', parameters, 'json');
		}
	},
	Raw: {
		Get: function(parameters) {
			return RendiliCore._base('GET', parameters, 'text');
		}
	},
	_base: function(method, parameters, dataType) {
		if (typeof parameters == 'string') {
			parameters = { url: parameters };
		}
		var promise = {
			onSuccess: null,
			onError: null,
			onUnauthorized: null,
			onForbidden: null,
			then: function(success, error, onUnauthorized, onForbidden) {
				this.onSuccess = success;
				this.onError = error;
				this.onUnauthorized = onUnauthorized == null ? RendiliCore.Settings.OnUnauthorized : onUnauthorized;
				this.onForbidden = onForbidden == null ? RendiliCore.Settings.OnForbidden : onForbidden;
			}
		};
		$.ajax({
			type: method,
			url: parameters.url,
			data: parameters.data,
			success: function(data) {
				if (promise.onSuccess != null) {
					promise.onSuccess(data);
				}
			},
			error: function(xhr, status) {
				switch(xhr.status) {
					case 401:
						if (promise.onUnauthorized != null) {
							promise.onUnauthorized(xhr, status);
						}
						break;
					case 403:
						if (promise.onForbidden != null) {
							promise.onForbidden(xhr, status);
						}
						break;
					default:
						if (promise.onError != null) {
							promise.onError(xhr, status);
						}
				}
			},
			dataType: dataType
		});
		return promise;
	}
}

export default RendiliCore;