<template>
    <f7-page name="dashboard" @page:afterin="updateView()">
		<f7-toolbar top tabbar>
			<f7-link tab-link="#Orders"   tab-link-active>Orders</f7-link>
			<f7-link tab-link="#run-schedule" >Run Schedule</f7-link>
			<f7-link tab-link="#tab-3">Despatch Schedule</f7-link>
			<f7-link tab-link="#tab-4">Search</f7-link>
		</f7-toolbar>
        <f7-navbar>
            <f7-nav-left><f7-link panel-open="left"><f7-icon f7="bars" color="blue"></f7-icon></f7-link></f7-nav-left>
            <f7-nav-title style="text-align:center; width:100%;"><img src="/assets/images/logo.svg" style="height:5rem;" alt="Taylors Embroidery and Print Logo"></f7-nav-title>
            <f7-nav-right>
                <f7-link href="/order/" text="New Order"></f7-link>
				<f7-link href="#" @click="signout()" text="Sign Out"></f7-link>
            </f7-nav-right>            
        </f7-navbar>
		<f7-tabs style="margin-top: 7rem;">
			<f7-tab  tab-active id="Orders" class="page-content">

				<f7-block class="no-top-margin no-bottom-margin">
					<h3 style="border-bottom: double 3px rgb(83, 112, 120);">Orders - Quoted ({{ orders.quoted.length }})</h3>
					<dashtable v-model="orders.quoted" 
					v-on:scheduleRun="scheduleRun('quoted', $event);" 
					v-on:markApproved="approve($event)"
					 showSchedule="1"
					 v-on:scheduleDespatch="scheduleDespatch('quoted', $event);" >
					</dashtable>
				</f7-block>	
			
				<f7-block class="no-top-margin no-bottom-margin">
					<h3 style="border-bottom: double 3px rgb(83, 112, 120);">Orders - New / Awaiting Garments ({{ orders.new.length }})</h3>
					<dashtable v-model="orders.new" 
						v-on:scheduleRun="scheduleRun('new', $event);" 
						v-on:scheduleDespatch="scheduleDespatch('new', $event);" 
						v-on:markApproved="approve($event)" 
						showSchedule="1"
						></dashtable>
				</f7-block>		
	
				<f7-block class="no-top-margin no-bottom-margin">
					<h3 style="border-bottom: double 3px #A5E1F0;">Orders - Awaiting Artwork ({{ orders.awaiting_art.length }})</h3>
					<dashtable v-model="orders.awaiting_art"
					  v-on:scheduleRun="scheduleRun('awaiting_art', $event);"
					  v-on:scheduleDespatch="scheduleDespatch('awaiting_art', $event);" 
					   showSchedule="1">
					</dashtable>
				</f7-block>						
				<f7-block  class="no-top-margin no-bottom-margin">
					<h3 style="border-bottom: double 3px #94C9D6;">Orders - Ready ({{ orders.ready.length }})</h3>
					<dashtable v-model="orders.ready" 
						v-on:scheduleRun="scheduleRun('ready', $event);" 
						v-on:markRun="run($event)" 
						v-on:scheduleDespatch="scheduleDespatch('ready', $event);" 
						showSchedule="1">
					</dashtable>
				</f7-block>						
				<f7-block class="no-top-margin no-bottom-margin">
					<h3 style="border-bottom: double 3px #79A5B0;">Orders - Run ({{ orders.run.length }})</h3>
					<dashtable v-model="orders.run" 
						v-on:markDespatched="despatched(event)" 
						v-on:scheduleDespatch="scheduleDespatch('run', $event);" 
						v-on:printDeliveryNote="deliverynote('run', $event)"
						showSchedule="0">
					</dashtable>
				</f7-block>		
				<f7-block class="no-top-margin no-bottom-margin">
					<h3 style="border-bottom: double 3px #AFEEFD;">Orders - Dispatched - For Invoice ({{ orders.dispatched.length }})</h3>
					<dashtable v-model="orders.dispatched" showSchedule="0" v-on:markInvoiced="invoiced($event)"></dashtable>
				</f7-block>																											
			
			</f7-tab>
			<f7-tab id="run-schedule" class="page-content"  v-on:tab:show="showRunSchedule = true" v-on:hide="showRunSchedule = false">
				<f7-block class="no-top-margin no-bottom-margin">
					<schedule v-if="showRunSchedule" :startDate="today" length="current_week" action="/scheduler/run_schedule" name="Run" status="Run" v-on:statuschange="runChange();"></schedule>
					<f7-button fill href="/schedule/">View Run Schedule</f7-button>
				</f7-block>
			</f7-tab>
			<f7-tab id="tab-3" class="page-content"  v-on:tab:show="showDespatchSchedule = true" v-on:hide="showDespatchSchedule = false">
				<f7-block class="no-top-margin">
					<schedule v-if="showDespatchSchedule" :startDate="today" length="0" action="/scheduler/dispatch_schedule" name="Despatch" v-on:statuschange="dispatchChange();" status="Despatched"></schedule>
					<f7-button fill href="/schedule/print_despatch" class="link external" target="_blank">Print Despatch Schedule</f7-button>
				</f7-block>			
			</f7-tab>
			<f7-tab id="tab-4" class="page-content"  v-on:tab:show="showSearcher = true" v-on:hide="showSearcher = false">
				<f7-block class="no-top-margin">
					<searcher v-if="showSearcher" :controller="'order'" v-on:clicked="f7router.navigate( '/order/' + $event)"></searcher>
				</f7-block>			
			</f7-tab>
		</f7-tabs>

		<minischeduler v-if="scheduleSection && showMiniScheduler" :OrderId="scheduleOrder.id" v-on:newJob="updateSchedule($event)" v-on:popup:closed="hideMiniScheduler()" />
		<minidespatcher v-if="scheduleSection && showMiniDespatcher" :OrderId="scheduleOrder.id" v-on:setDespatch="updateDespatch($event)" v-on:popup:closed="hideMiniDespatcher()" />
		<iframe id="myIFrame" v-if="delNoteFor" ref="dnote" v-on:load="printNote()" class="viewer" style="border:1px solid #FFFFFF; margin:0 auto; display: none;" title="Garment preview" 
				:src="'/delivery-note/' + delNoteFor" frameborder="1" scrolling="auto" width="297mm" height="210mm">
		</iframe>
	</f7-page>
</template>



<script>
import DashBoardTable from '../components/dashboard-table.vue';
import Schedule from '../components/schedule.vue';
import MiniScheduler from '../components/mini-scheduler.vue';
import MiniDespatcher from '../components/mini-despatcher.vue';
import Searcher from '../components/searcher.vue';
import {  f7, f7router } from 'framework7-vue';
import { onBeforeUnmount } from 'vue';

export default {
	props: {
		f7router: Object,
	},
    components: {
        dashtable : DashBoardTable,
		schedule: Schedule,
		minischeduler: MiniScheduler,
		minidespatcher: MiniDespatcher,
		searcher: Searcher
    },	
	data() {
		return {
			orders: {
				quoted: [],
				new: [],
				awaiting_art: [],
				ready: [],
				run: [],
				dispatched: []
			},
			showRunSchedule: false,
			showDespatchSchedule: false,
			showMiniScheduler: false,
			showMiniDespatcher: false,
			scheduleSection: null,
			scheduleOrder: null,
			printDelNote: false,
			delNoteFor: null,
			showSearcher: true

		 }
	},
	methods:  {
		runChange: function() {
			var vm=this;
			vm.$root.$refs['data-request'].sendGetRequest('/order/list/Ready')
			//ajaxmodule.sendGetRequest('/order/list/Ready')
			.then(data => {
				vm.orders.ready = data.list;
			}, function(){});		
			vm.$root.$refs['data-request'].sendGetRequest('/order/list/Run')
			.then(data => {
				vm.orders.run = data.list;
			}, function(){});	
		},
		dispatchChange: function() {
			var vm=this;
			vm.$root.$refs['data-request'].sendGetRequest('/order/list/Run')
			.then(data => {
				vm.orders.ready = data.list;
			}, function(){});		
			vm.$root.$refs['data-request'].sendGetRequest('/order/list/Dispatched')
			.then(data => {
				vm.orders.run = data.list;
			}, function(){});	
		},
		invoiced: function(index) {
			var vm = this;

			vm.$root.$refs['data-request'].sendGetRequest('/order/invoiced/' + this.orders.dispatched[index].id)
            .then(data => {
                if(data.status == 'OK') {
                    vm.order.status = vm.orders.dispatched[index].status = data.order_status;
                } else {
                    f7.dialog.alert('Could not save changes', "Error");
                }
            },  function(){}); 
		},
		approve: function(index) {
			var vm = this;
			vm.$root.$refs['data-request'].sendPostRequest({
				url: '/order/mark/' + this.orders.quoted[index].id,
				data : {markas: 'approved'}
			})
            .then(data => {
                if(data.status == 'OK') {
					var order = vm.orders.quoted[index];
                    order.status = data.order_status;
					vm.orders.quoted.splice(index,1);
					if(data.order_status == 'Awaiting artwork')
						vm.orders.awaiting_art.push(order);
					else if(data.order_status == 'New') {
						vm.orders.new.push(order);
					}else {
						vm.orders.ready.push(order);
					}
                } else {
                    f7.dialog.alert('Could not save changes', "Error");
                }
            },  function(){}); 
		},	
		run: function(index) {
			var vm = this;
			vm.$root.$refs['data-request'].sendPostRequest({
				url: '/order/mark/' + this.orders.ready[index].id,
				data : {markas: 'Run'}
			})
            .then(data => {
                if(data.status == 'OK') {
					var order = vm.orders.ready[index];
                    order.status = 'Run';
					vm.orders.ready.splice(index,1);
					vm.orders.run.push(order);
                } else {
                    f7.dialog.alert('Could not save changes', "Error");
                }
            },  function(){}); 
		},	
		despatched: function(index) {
			var vm = this;
			vm.$root.$refs['data-request'].sendPostRequest({
				url: '/order/mark/' + this.orders.ready[index].id,
				data : {markas: 'Despatched'}
			})
            .then(data => {
                if(data.status == 'OK') {
					var order = vm.orders.run[index];
                    order.status = 'Despatched';
					vm.orders.run.splice(index,1);
					vm.orders.dispatched.push(order);
                } else {
                    f7.dialog.alert('Could not save changes', "Error");
                }
            },  function(){}); 
		},			
		scheduleChange: function() {
			this.updateView();
			this.showSchedules = 0;
			this.$nextTick(() => this.showSchedules = 1);
		},
		scheduleRun: function(group, id) {
			this.scheduleSection = group;
			this.scheduleOrder = this.orders[group][id];
			this.showMiniScheduler = true;
		},
		scheduleDespatch: function(group, id) {
			this.scheduleSection = group;
			this.scheduleOrder = this.orders[group][id];
			this.showMiniDespatcher = true;
		},
		hideMiniScheduler() {
			this.showMiniScheduler = false;
		},
		hideMiniDespatcher() {
			this.showMiniDespatcher = false;
		},
		updateSchedule: function(event) {
			var vm = this;
			console.log(this.orders[this.scheduleSection][this.scheduleOrder]);
			this.scheduleOrder.run_schedule = {...event};
		},
		updateDespatch: function(event) {
			var vm = this;
			console.log(this.orders[this.scheduleSection][this.scheduleOrder]);
			this.scheduleOrder.dispatch_schedule = {...event};
		},
		deliverynote(group, id) {
			this.printReady = false;
			this.delNoteFor = this.orders[group][id].id;
			this.printDelNote = true;
		},
		printNote() {
			console.log('onload');
			
				this.$refs.dnote.contentWindow.print();
		},
		updateView: function(type = '') {
			
			var vm = this;

			
			
			if(type == '' || type == 'new_quote') {
				vm.$root.$refs['data-request'].sendGetRequest('/order/list/Quote')
				.then(data => {
					vm.orders.quoted = data.list;
				}, function(err) {});

				vm.$root.$refs['data-request'].sendGetRequest('/order/list/New')
				.then(data => {
					vm.orders.new = data.list;
				}, function(){});
			}

			if(type == '' || type == 'awaiting_art') {
				vm.$root.$refs['data-request'].sendGetRequest('/order/list/Awaiting_artwork')
				.then(data => {
					vm.orders.awaiting_art = data.list;
				}, function(){});	
			}
	
			if(type == '' || type == 'ready') {
				vm.$root.$refs['data-request'].sendGetRequest('/order/list/Ready')
				.then(data => {
					vm.orders.ready = data.list;
				}, function(){});		
			}

			if(type == '' || type == 'run') {
				vm.$root.$refs['data-request'].sendGetRequest('/order/list/Run')
				.then(data => {
					vm.orders.run = data.list;
				}, function(){});		
			}

			if(type == '' || type == 'despatched') {
				vm.$root.$refs['data-request'].sendGetRequest('/order/list/Despatched')
				.then(data => {
					vm.orders.dispatched = data.list;
				}, function(){});	
			}
		},
		signout: function() {
			RendiliCore.Json.Get('/user/signout').then(location.reload());
		},
		mounted: function () {
			console.log('home mounted');
		},

	},
	computed: {
		today: function() {
			var today = new Date();
			var dd = String(today.getDate()).padStart(2, '0');
			var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
			var yyyy = today.getFullYear();

			return yyyy + '-' + mm + '-' + dd;
		},
		newsent: function () {
			return this.orders.quoted.concat(this.orders.new).sort()
		}

	},

}

</script>

