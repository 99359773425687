<template>
    <f7-popup :backgroupUnique="true" :opened="isOpen" >
        <f7-page>
        <f7-navbar title="Schedule Job">
            <f7-nav-right>
            <f7-link popup-close>Close</f7-link>
            </f7-nav-right>
        </f7-navbar>

        <f7-block style="margin-top: 4rem;">
            <template v-if="scheduleAdHoc">
                <autosearch ref="account" labelText="Customer Account" url="/account/search" :minchars="1" param="" v-on:refine="schedule_item.account_id = $event.code" v-model="schedule_item.account_id" position="relative"></autosearch>
            </template>
            <f7-list no-hairlines-md style="margin-top:0; margin-bottom:0;">
                 <f7-list-input v-if="scheduleAdHoc" ref="order" label="Job Name" type="text" v-model:value="schedule_item.name" ></f7-list-input>  
                
                <f7-list-input v-else-if="!OrderId" label="Order" type="select" v-model:value="schedule_item.order_id" @change="getOrder()">
                    <option v-for="(order, i) in orders" :key="i" :value="order.id">{{ order.job_name }}: {{ order.account_name }}</option>
                </f7-list-input>      	
                <h3 v-else><span v-html="jobDetail"></span></h3>
            </f7-list>

            <f7-list>
                <f7-list-input label="Run dates" type="datepicker" readonly v-model:value="schedule_item.datelist" 
                :calendar-params="{ dateFormat: 'dd/mm/yy', multiple: true, header:false, inline:true,  }">
                </f7-list-input>
            </f7-list>
            <h5>Machine</h5>
            <f7-chip v-for="(machine, i) in machines"
                :text="machine.name" 
                @click="schedule_item.machine = machine.id"    
                style="cursor: pointer; margin:3px; font-size: 1.2rem;"
                :media="machine.name.charAt(0)"
                :media-bg-color="schedule_item.machine == machine.id ? 'green' : 'yellow'"
                >
            </f7-chip>
            <template v-if="schedule_item.order_id && schedule_item.have_order">
                <h4>Total Garments: {{ detailed_orders[schedule_item.order_id].garment_total }}, Total Customisations: {{  detailed_orders[schedule_item.order_id].customisations_total }}</h4>
                <f7-block class="no-top-margin no-bottom-margin">
                    <div class="data-table" style="background-color: #FFFFFF;">
                    <table >
                        <thead>
                            <tr>
                                <th>Garment</th>
                                <th>Quantity</th>
                                <th>Positions</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in  detailed_orders[schedule_item.order_id].order_details" v-bind:key="index">
                                <td>{{ item.garment_type }}</td>
                                <td>{{ item.quantity }}</td>
                                <td>{{ item.customer_garment.customer_garment_artwork_placement.length }}</td>
                                <td>
                                    <p style="margin: 0.3rem" v-for="(placement, i) in item.customer_garment.customer_garment_artwork_placement">{{ placement.customer_artwork.artwork_type }}: {{ placement.customer_artwork.name }}</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    </div>
                </f7-block>

            </template>
            <f7-button class="button-large button-fill" @click="saveSchedule()">Save</f7-button>
            
        </f7-block>
        <template v-if="schedule_item.error">
            <p>! {{ schedule_item.error }}</p>
        </template>
        </f7-page>
    </f7-popup>
</template>

<script>

import AutoSearch from './../components/auto-search.vue';
import {  f7 } from 'framework7-vue';
export default {
	props: [ 'OrderId', 'adhoc' ],
    components: {
		autosearch : AutoSearch,
    },	
    emits:['newJob', 'closeMe'],
	data() {
		return {
			machines: [],
			machine_count: 0,
			scheduleAdHoc: false,
			schedule_item : {
				order_id: null,
				datelist: [],
				name: null,
				account_id: null,
				machine: 0,
				have_order: false
			},
			orders: [],
			detailed_orders: {},
			job_info: null,
            isOpen: false
		}
	},
	methods:  {
		getMachines: function () {
            var vm = this;
            vm.$root.$refs['data-request'].sendGetRequest('/machine/list/')
            .then(data => {
                if(data.status == 'OK') {
                    vm.machines = data.list;
					vm.machine_count = data.count;
                } 
            }, function(){});
        },
		getUnscheduledJobs: function() {
            var vm = this;
            vm.$root.$refs['data-request'].sendGetRequest('/order/unscheduled/')
            .then(data => {
                if(data.status == 'OK') {
                    vm.orders = data.list;
                } 
            }, function(){});
		},
		saveSchedule: function() {
			if(this.schedule_item.name && this.schedule_item.datelist.length > 0 && this.schedule_item.account_id) {
				var vm = this;
				vm.$root.$refs['data-request'].sendPostRequest({
					url: '/scheduler/schedule_run',
					data : vm.schedule_item
				})			
				.then(data => {
					if(data.status == 'OK') {
                        data.item.garments = data.garments;
                        vm.$emit('newJob', data.item);
                        vm.hideMiniScheduler();
					} 
				}, function(){});
			}else {
				//this.adhoc_item.error = 'Please enter job name, customer account and date(s)!';
				f7.dialog.alert('Please enter job name, customer account and date(s)!', "Error");
			}
		},

		showScheduler(adhoc = false) {
			this.scheduleAdHoc = adhoc;
			//this.showMiniScheduler = true;
		},
		hideMiniScheduler() {
			this.job_info = null;
			this.schedule_item = {
				order_id: null,
				datelist: [],
				name: null,
				account_id: null,
				machine: 0,
				have_order: false
			};
            //this.showMiniScheduler = false;
            this.isOpen = false;
		},
		getOrder() {
            var vm = this;
			var orderdetail = null;
			this.schedule_item.have_order = false;
			if(vm.detailed_orders.hasOwnProperty(this.schedule_item.order_id)) {
				this.schedule_item.have_order = true;
				this.schedule_item.name = data.order.job_name;
				this.schedule_item.account_id = data.order.account_id;
				return;
			}
				

            vm.$root.$refs['data-request'].sendGetRequest('/order/load/' + this.schedule_item.order_id)
            .then(data => {
                if(data.status == 'OK') {
					//vm.detailed_orders[this.schedule_item.order_id] = {}
                    vm.detailed_orders[vm.schedule_item.order_id] = data.order;
					vm.schedule_item.have_order = true;
					vm.schedule_item.name = data.order.job_name;
					vm.schedule_item.account_id = data.order.account_id;
                    var dates = [];
                     data.order.run_schedule.datelist.forEach(f => 
                        dates.push(new Date(f))
                    );
                    vm.schedule_item.datelist = dates;
                    vm.schedule_item.machine = data.order.run_schedule.machine.id;
                } 
            },function() {});
		}
	},
	mounted: function() {
		this.getMachines();
		this.getUnscheduledJobs();
        if(this.OrderId){
            this.schedule_item.order_id = this.OrderId;
            this.getOrder();
        }
    	if(this.scheduleAdHoc) {
            this.scheduleAdHoc = this.adhoc
        }
       
        this.isOpen = true;
	},
	computed: {
		today: function() {
			var today = new Date();
			var dd = String(today.getDate()).padStart(2, '0');
			var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
			var yyyy = today.getFullYear();

			return yyyy + '-' + mm + '-' + dd;
		},
        jobDetail: function() {
            console.log('jobdetail', this.detailed_orders[this.OrderId])
            if(this.detailed_orders.hasOwnProperty(this.OrderId)) {
                return 'Order Ref: ' + this.OrderId + '<br />' + this.detailed_orders[this.OrderId].job_name + ': ' + this.detailed_orders[this.OrderId].account.account_name 
            } 
        }

	}
}
</script>
