<template>
<f7-card :title="account_name  + ' Garment: ' + garment.name">
    <p v-if="garment.related_order_refs">
        Orders with this garment: {{ garment.related_order_refs.join(', ') }}
    </p>
    <f7-block v-show="edit">
        <p>Click body part to add artwork</p>
        <div ref="container" style="position:relative; background-color:white; height: 400px;">
            <canvas ref="vcanvas" id="canvas" style="width:400px; height:400px; position: absolute; left: 0; top: 0; z-index: 0; background-color: white;"></canvas>
            <canvas ref="vcanvas2" id="canvas2" style="width:400px; height:400px; position: absolute; left: 0; top: 0; z-index: 1; background-color: transparent;" @click="mark($event)"></canvas>            
        </div>
    </f7-block>
    <f7-block v-if="edit">
        <template v-for="(item,index) in drawing.positions">
            <h2>Position {{ item.number }}</h2>
            
            <template v-if="item.artwork">
                <p>Artwork: {{item.artwork.name }} - {{ item.artwork.artwork_type }}</p>
                
                <img v-if="item.artwork.preview" :src="item.artwork.preview">
                
            </template>
            <template v-else>
                <p>Choose artwork for position {{ item.number }}, select from existing or add new.</p>
                
                <div v-if="customer_artwork_list && !new_art">
                    <h3>Existing Artwork</h3>
                    <f7-link v-for="(line, i) in customer_artwork_list" 
                        :title="customer_artwork_list[i].name + ' ' + customer_artwork_list[i].artwork_type"
                        class="bordered"
                        :style="'background-image:url(' + customer_artwork_list[i].preview + ')'"
                        @click="drawing.positions[index].artworkid = line.id; drawing.positions[index].artwork = line"
                    >
                    </f7-link>
                </div>
                <h3>Add New Artwork</h3>
                <f7-button outline @click="new_art = (new_art == null ? item.number : null)">{{ new_art == item.number ? "Cancel Add New Art" : "Add New Art" }}</f7-button>
                <template v-if="new_art == item.number">
                    
                    <artwork :account-id="garment.account_id" v-on:artwork="setArtwork($event, item)" />
                </template>
            </template>
            <f7-block style="margin-top:1rem;">
                <div class="grid grid-cols-2 grid-gap">
                    <div>
                        <f7-button v-if="item.artwork" class="button-small button-fill" @click="changeArt(item.number)">{{ item.artwork != null ? 'Change' : 'Choose' }} Artwork</f7-button>
                    </div>
                    <div><f7-button class="button-small button-fill" @click="removeMark(item.number)">Remove Position {{ item.number }}</f7-button></div>
                </div>
            </f7-block>
        </template>
        <f7-list>
            <f7-list-input label="Name" placeholder="name your garment" v-model:value="garment.name" />
            <f7-list-input label="Notes" placeholder="enter any garment notes" v-model:value="garment.notes" />
        </f7-list>
        <f7-block style="margin-top:1rem;">
            <div class="grid grid-cols-2 grid-gap">
                <div>
                    <f7-button v-if="edit" large tonal @click="editGarment(false)">Cancel Edit</f7-button>
                </div>
                <div><f7-button large tonal @click="save()">Save Garment</f7-button></div>
            </div>
        </f7-block>
    </f7-block>
    <f7-block v-else>
        <div style="background-color:white; text-align:center;" >
            <iframe  ref="g_viewer" class="viewer" style="border:1px solid #FFFFFF; margin:0 auto;" title="Garment preview" 
                    :src="garment.img_path" frameborder="1" scrolling="auto" width="400" height="400">
            </iframe>
        </div>
        <template v-for="(item,index) in garment.customer_garment_artwork_placement">
            <h2>Position {{ item.location_number }}</h2>
            <template v-if="item.customer_artwork">
                <p>Artwork: {{item.customer_artwork.name }} - {{ item.customer_artwork.artwork_type }}</p>
                <img v-if="item.customer_artwork.preview" :src="item.customer_artwork.preview">
            </template>
        </template>
        <template v-if="garment.notes">
            <h2>Notes</h2>
            <p v-if="garment.notes">{{ garment.notes }}</p>
        </template>
        <template v-if="!used_on_orders">
        <f7-button  large tonal @click="editGarment(true)">Edit Garment</f7-button>
    </template>
    </f7-block>

</f7-card>

</template>

<script>
import Artwork from './artwork.vue';
import {  f7 } from 'framework7-vue';
import { nextTick } from 'vue';
export default {
    components: {
        artwork : Artwork
    },
    emits: ['garment'],
    props: {
        garmentId: {
            type: [Number, null],
            required: true
        },
        accountId: {
            type: [Number, null],
            required: false
        },
        garmentType: {
            type: [String],
            required: false
        },
        configType: {
            type: [String],
            required: false
        },
    },
    data: function () {
        return {
            drawing: {
                canvas: null, 
                context : null,
                markedcanvas: null,
                markedcontext: null,
                img: new Image(),
                aspectRatio: 1,
                finalimage: null,
                positions: [],
            },
           garment: {
            id: null,
            account_id: null,
            garment_type: null,
            name: null,
            filename: null,
            config_type: null,
            notes: null,
            customer_garment_artwork_placement: [],
            related_order_refs: [],
            img_path: null
           },
           account_name: null,
           customer_artwork_list: [],
           new_art: null,
           edit: false
        }
    },
    methods: {
        getAccount() {
            var vm = this;
            vm.$root.$refs['data-request'].sendGetRequest({
			    url: '/account/name/' + this.garment.account_id,
            })
            .then(data => {
                if(data.status == 'OK') {
                    vm.account_name = data.name;
                    vm.getCustomerArtworkList();
                } 
            }, function() {});
        },
        loadGarment(_callback) {
            var vm = this;
            vm.$root.$refs['data-request'].sendGetRequest({
			url: '/garment/get/' + this.garment.id,
            })
            .then(data => {
                if(data.status == 'OK' && data.model.id) {
                    vm.garment.id = data.model.id;
                    vm.garment.account_id = data.model.account_id;
                    vm.garment.name = data.model.name;
                    vm.garment.filename = data.model.filename;
                    vm.garment.garment_type = data.model.garment_type;
                    vm.garment.config_type  = data.model.config_type;
                    vm.garment.notes = data.model.notes;
                    vm.garment.customer_garment_artwork_placement = data.model.customer_garment_artwork_placement;
                    vm.garment.related_order_refs = data.model.related_order_refs;
                    vm.garment.img_path = data.model.img_path + '?d=' + new Date();
                    vm.getAccount();
                    if(_callback)
                        _callback();
                     
                } else {
                    f7.dialog.alert("garment not found!", "Error");
                } 
            })
            
        },
        save: function() {
            var errors = [];
            if(!this.garment.name) {
                errors.push('Garment name');
            }

            if(errors.length) {
                f7.dialog.alert(errors.join("<br>"), "Missing data");
                return;
            }
            var vm = this;
            this.drawing.context.drawImage(this.drawing.markedcanvas,0,0);
            this.drawing.finalimage = this.drawing.canvas.toDataURL('image/png', 1.0);
            this.new_art = null;
            vm.$root.$refs['data-request'].sendPostRequest({
			url: '/garment/save/',
			data : {
                'id': this.garment.id,
				'layout' :this.drawing.finalimage, 'name': this.garment.name, 'positions':  this.drawing.positions, 'account_id' : this.garment.account_id, 'type': this.garment.garment_type,
                'config_type': this.garment.config_type
            }
            })
            .then(data => {
                if(data.status == 'OK') {
                    //vm.$emit('addconfiguredgarment', {'line' : vm.orderline,'garment' :data.garment});
                    //this.closeChooser();
                    vm.garment.id = data.garment.id;
                    f7.dialog.alert("Garment Saved");
                    vm.$emit('garment', data.garment);
                    vm.loadGarment();
                    
                    vm.edit = false;
                } else {
                    vm.errors.push(data.message);
                }
            }, function() {}); 
        },
        async loadTemplate(url) {
            return new Promise(r => { let i = new Image(); i.onload = (() => r(i)); i.src = url; });
        },
        async getCanvas() {
            console.log('getting canvas');
            this.drawing.canvas = this.$refs.vcanvas;
            this.drawing.markedcanvas = this.$refs.vcanvas2;

            if(this.garment_type == 'Towel' || this.garment_type == 'Blanket') {
                this.drawing.img = await this.loadTemplate("/assets/images/towel.jpg");
            }else {
                this.drawing.img = await this.loadTemplate("/assets/images/body.jpg");
            }

            this.drawing.aspectRatio = this.drawing.img.naturalWidth / this.drawing.img.naturalHeight;

            var width = 400 < this.drawing.img.naturalWidth ? 400: this.drawing.img.naturalWidth;
            var height = 400 < this.drawing.img.naturalHeight ? 400 : this.drawing.img.naturalHeight; 
            if (width > height * this.drawing.aspectRatio) {
                width = height * this.drawing.aspectRatio;
            } else {
                height = width / this.drawing.aspectRatio;
            }
            if (this.drawing.canvas.width != width || this.drawing.canvas.height != height) {
                this.drawing.canvas.width = width;
                this.drawing.canvas.height = height;
                this.drawing.markedcanvas.width = width;
                this.drawing.markedcanvas.height = height;            
            }

            this.drawing.context = this.drawing.canvas.getContext("2d");
            this.drawing.markedcontext = this.drawing.markedcanvas.getContext("2d");
            this.drawing.context.drawImage(this.drawing.img, 0, 0, this.drawing.canvas.width, this.drawing.canvas.height);
            
            //add exitsing marks
            this.drawing.positions = [];
            if(this.garment.customer_garment_artwork_placement.length > 0) {
                this.garment.customer_garment_artwork_placement.forEach(f => {
                    this.addMark(f.location_number, f.x_pos,f.y_pos, f.id, f.artwork_id, f.customer_artwork);
                })
            }
        },
        mark: function(event, markno = null) {
            var pos = this.getMousePos(event);
            this.addMark(this.nextMarkNumber, pos.x, pos.y, null,null, null);
        },
        addMark: function(markno,x , y, placementid, artworkid, artwork) {
            console.log('adding mark ' + markno + ' ' + x + ' ' + y + ' ' + artworkid);
            this.drawing.markedcontext.fillStyle = "#000000";
            this.drawing.markedcontext.font = '14px Arial';
            var position = {
                number: parseInt(markno),
                x: parseFloat(x),
                y: parseFloat(y), 
                width: 18, 
                height: 18,
                artworkid : artworkid == null ? null : parseInt(artworkid),
                placementid : placementid == null ? null : parseInt(placementid),
                artwork: artwork,
                notes : null,
            };
            this.drawing.positions.push(position);
            this.drawing.markedcontext.fillRect (position.x, position.y, position.width, position.height);
            this.drawing.markedcontext.fillStyle = "#ffffff";
            this.drawing.markedcontext.fillText(position.number, position.x + 5, position.y +14);
            
        },
        removeMark: function (event) {
            var result = this.drawing.positions.filter(obj => {
                return obj.number === event;
            });
            if(result.length) {
                console.log(result[0]);

                this.drawing.markedcontext.clearRect(result[0].x - 5, result[0].y - 5, result[0].width + 10, result[0].height + 10);

                this.drawing.positions = this.drawing.positions.filter(item => item.number != event);
            }
        },
        getMousePos(evt) {
            var rect = this.drawing.canvas.getBoundingClientRect(), // abs. size of element
                scaleX = this.drawing.canvas.width / rect.width,    // relationship bitmap vs. element for x
                scaleY = this.drawing.canvas.height / rect.height;  // relationship bitmap vs. element for y

            return {
                x: ((evt.clientX - rect.left) * scaleX) - 9,   // scale mouse coordinates after they have
                y: ((evt.clientY - rect.top) * scaleY)-9     // been adjusted to be relative to element
            }
        },
        changeArt(event) {
            
            var result = this.drawing.positions.filter(obj => {
                return obj.number === event;
            });
            if(result.length) {
                result[0].artwork = null;
                result[0].artworkid = null;
            }
        },
        getCustomerArtworkList() {
            var vm = this;
            this.customer_artwork_list = [];
            vm.$root.$refs['data-request'].sendPostRequest({
			url: '/artwork/list/',
			data : {
				'account_id' : vm.garment.account_id
            }    
            })        
            .then(data => {
                if(data.status == 'OK') {
                    vm.customer_artwork_list = data.list;
                } 
            }, function() {});
        },
        setArtwork(artwork, position) {
            //catch newly added arwork
            this.customer_artwork_list.push(artwork);
            var result = this.drawing.positions.find(obj => {
                return obj.number == position.number;
            });
            console.log(result);
            result.artwork = artwork;
            result.artworkid = artwork.id;
        },
        editGarment(status) {
            this.edit= status;
            if(!this.garmentId) {
                this.$emit('garment', null);
            }
            this.waitone();
        },
        async waitone() {
            if(!this.garment.used_on_orders) {
                await nextTick();
                this.getCanvas(); //canvas for new only
            }
        }
    },
    mounted: function() {
        if(this.garmentId != null) {
            this.garment.id = this.garmentId;
            this.loadGarment(this.waitone);
        } else  {
            console.log('shitpoo');
            this.edit = true;
            this.garment.account_id = this.accountId;
            this.getAccount();
            this.getCanvas(); //canvas for new only
            this.garment.config_type = this.configType;
            this.garment.garment_type = this.garmentType;
            this.garment.name = this.garment.garment_type;
        }

        
    }, 
    computed: {
        nextMarkNumber() {
            if(this.drawing.positions.length == 0)
                return 1;

            var positions = this.drawing.positions.map((pos) => parseInt(pos.number));
            console.log(positions);
            positions.sort(function(a, b) {
                return b - a;
            });
            console.log(positions);
            return positions[0] + 1;
        }
    }
}
</script>